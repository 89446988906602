import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faAngleRight, faHome } from '@fortawesome/free-solid-svg-icons'
import { Card, Col, Row, Container, Table } from 'react-bootstrap'
import Programmain from '../SideMenu/ProgramMenu'
import Enquries from "../../HomePage/EnquriesForm/Enquries";

export default function PhD() {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <Container className='Aboutpage'>
            <Row >
                <Col lg={3}>
                    <Programmain />
                </Col>
                <Col lg={9}>
                    <Card className='p-4'>
                        <p>
                            <a href='/'><span className='mx-1 crumb'><FontAwesomeIcon icon={faHome} className='mx-1' /></span></a>
                            <span className='mx-1'>/</span>
                            <a href='/programme' className='crumb'>
                                <span className='mx-1  crumb' >Programme</span>
                            </a>
                            <span className='mx-1'>/</span>
                            <span className='mx-1  crumbcolor' href=''>Ph.D</span></p>
                        <h4>Ph.D</h4>
                        <p className="ParaStyle">
                            Research graduates are involved in the R&D of new therapeutics as well as ensure their
                            quality control. Being major exporter country of Pharma drugs, the demand of skilled and
                            qualified researchers in R&D of Pharma industry is more. Any candidate can pursue this course
                            after completing M.Pharm degree from recognized institution / university.
                            Full time: 03 Years, Part time: 04 Years</p>

                        <h5>Ph.D Road Map</h5>
                        <div className="PhD">
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} color={"#a60d1e"} className="mx-3" />
                                Selection of guide (Internal or External).
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} color={"#a60d1e"} className="mx-3" />
                                Preparation of synopsis and approved by the guide.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} color={"#a60d1e"} className="mx-3" />
                                Approval of Institutional Animal Ethical Committee Clearance.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} color={"#a60d1e"} className="mx-3" />
                                Apply to The Tamilnadu Dr.MGR Medical University for Eligibility certificate.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} color={"#a60d1e"} className="mx-3" />
                                Approval of Provisional Registration (Full time: 03 Years, Part time: 04 Years).
                            </p>
                        </div>
                        <h5>AVAILABLE GUIDE DETAILS</h5>
                        <h6>
                            <FontAwesomeIcon icon={faAngleDoubleRight} color={"#a60d1e"} className="mx-3" />
                            INTERNAL GUIDE
                        </h6>
                        <Table bordered responsive="sm">
                            <thead className="text-center">
                                <tr>
                                    <th>S.no</th>
                                    <th>Name of the guide</th>
                                    <th>Designation</th>
                                    <th>Specialization</th>
                                </tr>
                            </thead>
                            <tbody className="text-center">
                                <tr>
                                    <td>1</td>
                                    <td>Dr.R.Venkatanarayanan</td>
                                    <td>Principal</td>
                                    <td>Pharmacognosy</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Dr.D.Benito Johnson</td>
                                    <td>Professor & Head of the Department</td>
                                    <td>Pharmacology</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Dr.Barish</td>
                                    <td>Professor & Head of the Department</td>
                                    <td>Pharmaceutics</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Dr.S.S.Rajendran</td>
                                    <td>Professor</td>
                                    <td>Pharmaceutical Chemistry</td>
                                </tr>
                            </tbody>
                        </Table>
                        <h6>
                            <FontAwesomeIcon icon={faAngleDoubleRight} color={"#a60d1e"} className="mx-3" />
                            EXTERNAL GUIDE
                        </h6>
                        <p className="ParaStyle">
                        Any faculty members working in The Tamilnadu Dr.MGR Medical University affiliated
college and recognized guide in the university can guide Ph.D research programme both full time
and part time, who will be named as external guide. The candidate may use this facility and do
research work in Ph.D.
                        </p>
                        <p onClick={() => setModalShow(true)} className='DownloadBrochureButton m-auto p-lg-1'>Talk to the Expert</p>
                    </Card>
                </Col>
            </Row>
            <Enquries show={modalShow} onHide={() => setModalShow(false)} />
        </Container>
    )
}
