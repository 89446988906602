import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Card, Col, Row, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Programmain from '../SideMenu/ProgramMenu'

export default function ProgrammeMain() {
    const ProgrameeDetails=
    [
        {
            Heading:"B.Pharm",
            Content:"Gain practical and theoretical experience of fundamentals in pharmacy",
            Link:"/programme/B.pharm",
            img:"/images/button/BPharm.png"
        },
        {
            Heading:"M.Pharm",
            Content:"The complexity of medical formulae and critical problems in making drugs, M.Pharm graduates have advantage in R&D. This programme is designed with specialization in area wise manufacturing of drugs and it bringing a finished product ready to market.",
            Link:"/programme/M.pharm",
            img:"/images/button/Mpharm.png"
        },
        {
            Heading:"Phram.D",
            Content:"Learn more on Pharmacy Practice which involves developing the professional roles of pharmacists.",
            Link:"/programme/Pharm.D",
            img:"/images/button/PharmD.png"
        },
        {
            Heading:"Ph.D",
            Content:"Get Expertize in research and development",
            Link:"/programme/Ph.D",
            img:"/images/button/PhD.png"
        },
    ]
    const ProgrameeView=ProgrameeDetails.map((value,index)=>
        <Col lg={6} md={6}>
            <h6>{value.Heading}</h6>
            <p>{value.Content}</p>
            <Link to={value.Link} className="text-decoration-none">
                <img src={value.img} alt={`ViewDetails ${index+1}`} className="img img-fluid"/>
            </Link>
        </Col>
        )
    return (
        <Container className=" Aboutpage  ">
            <Row>
                <Col lg={3}>
                    <Programmain />
                </Col>
                <Col lg={9}>
                    <Card className='p-4'>
                        <p>
                            <a href='/'>
                                <span className='mx-1 crumb'><FontAwesomeIcon icon={faHome} className='mx-1' /></span>
                            </a>
                            <span className='mx-1'>/</span>

                            <span className='mx-1  crumb' >Programme</span>

                        </p>
                        <h4>Programme</h4>
                        <p>
                        Traditionally, Pharmacy has been known as an art and science of making medicines.
It is branch of health science that deals with the preparation and dispensing of drugs. It aims
to ensure the safe and effective use of pharmaceutical drugs.
                            </p>
                        <Row className='my-5 '>
                            {ProgrameeView}
                        </Row>

                        <p className='brochurebutton m-md-auto m-auto'>Talk to the Expert</p>
                    </Card>
                </Col>

            </Row>
        </Container>
    )
}
