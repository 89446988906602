import React from "react";
import { Container, Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faAngleDoubleRight,
  
} from "@fortawesome/free-solid-svg-icons";
import AboutMain from "../SideMenu/AboutMenu";

export default function Management() {
  return (
    <Container className="Aboutpage">
      <Row>
        <Col lg={3}>
          <AboutMain />
        </Col>
        <Col lg={9} md={12}>
          <Card className="p-4">
            <p>
              <a href="/">
                <span className="mx-1 crumb">
                  <FontAwesomeIcon icon={faHome} className="mx-1 " />
                </span>
              </a>
              <span className="mx-1  crumb">About Us</span>
              <span className="mx-1">/</span>
              <span className="mx-1  crumbcolor">The Management</span>
            </p>

            <div className=" ParaStyle my1 px-2">
              <h4 className="my-2">The Management</h4>
              <div>
                <Row>
                  <Col lg={9} md={9}>
                    <h5>Dr. K. V. Kupusamy, Chairman</h5>
                    <p>
                      Dr.KV Kupusamy is a shining living example of how one can
                      rise from a humble beginning to reach the level of being a
                      Chairman, presiding over a colossal empire of educational
                      institutions, by sheer hand hard work, dedication and
                      devotion to a committed cause. Known for his magnanimity,
                      spiritual bent of mind and suave cool demeanour, he is
                      widely recognized and respected by all. To him, the
                      panacea for the maladies of mankind lies in lack of
                      Education, Healthcare and farming.
                    </p>
                  </Col>
                  <Col lg={3} md={3}>
                    <img
                      src="/images/web/chairman.jpg"
                      className="img-responsive mx-lg-0 mx-md-0 mx-3 my-lg-2 my-md-2"
                      alt="chairman"
                    ></img>
                  </Col>
                  <h6>Education</h6>

                  <p>
                    He has proved and distinguished himself as a great visionary
                    and an able administrator. He has carved out a niche in the
                    Educational arena by establishing about 95 reputed
                    educational institutions dotted all over Tamilnadu.
                  </p>
                  <h6>Agriculture</h6>
                  <p>
                    His insatiable interest for agriculture has resulted in the
                    formation of exuberant agricultural farms at Tenkasi,
                    Thadankulam, Thanjavur, Sempatti and Sengathurai.
                  </p>
                  <h6>Healthcare</h6>
                  <p>
                    His soft corner for healthcare is reflected by the RVS
                    Ayurveda, Homeopathy and Siddha Hospitals in addition to the
                    respective educational institutions.
                  </p>
                  <h6>Awards and Achievements</h6>
                  <p>
                    He was awarded the Doctor of Philosophy in Education by
                    Dianora University (Italy) in 1993. He was also awarded
                    sovereign Excellency Knight, VIDYA JYOTHI for his
                    achievements in the field of Educational Service in 1994,
                    GLORY OF INDIA by International Friendship Society of India,
                    UDYOG EXCELLENCE, VIJAY SHREE and BHARAT JYOTHI for his
                    achievements in the field of Technology, Public life, Social
                    Service, Education, Business &amp; Industry in 1994.
                  </p>
                </Row>
              </div>
              <div>
                <Row>
                  <Col lg={9} md={9}>
                  <h5>Dr.K.Senthil Ganesh, the Managing Trustee</h5>
                    <p className="ParaStyle">
                      Dr.K.Senthil Ganesh is the Managing Trustee of RVS
                      Educational Institutions which runs more than 50
                      institutions with 20,000 students. He played a pivotal
                      role in expanding the RVS group from 2000 till 2008. Along
                      with the Chairman Dr. K.V.Kuppuswamy, he was instrumental
                      in putting together a team of eminent academicians,
                      management and finance consultants and started 10 new
                      institutions comprising of Dental, Health Science,
                      Engineering and Alternative medical colleges over the same
                      period.
                    </p>
                  </Col>
                  <Col lg={3} md={3}>
                    <img
                      src="/images/web/trustee.jpg"
                      className="img-fluid "
                      alt="Trustee"
                    ></img>
                  </Col>
                </Row>
                <p className="ParaStyle">
                  He has around 25 years of rich experience in senior management
                  roles and in various business functions including Strategy,
                  Finance and Marketing. From 2008 he embarked on a
                  transformation project. Armed with the strategic insight that
                  Digital Transformation and Disruption will be growth themes
                  for the next decade he started transforming a division of RVS
                  group of colleges comprising of Art and Science, Health
                  Sciences and Ayurveda institutions. With the vision of
                  providing relevant education for the digital era, many
                  curricular, co-curricular and administrative initiatives were
                  successfully implemented since 2008. As a result, this
                  division of RVS group grew from 2500 students to over 7500 and
                  also won many accolades over a period of 10 years.
                </p>
              </div>
              <h5>Accolades</h5>
              <p className="HeadingStyle my-1">RVS Arts & Science College</p>
              <p className="ParaStyle">
                <FontAwesomeIcon
                  icon={faAngleDoubleRight}
                  color={"#a70000"}
                  className="mx-1"
                />
                First place in Bharathiar University for enrolling maximum
                number of international students for the academic year 2007.
              </p>{" "}
              <p className="ParaStyle">
                <FontAwesomeIcon
                  icon={faAngleDoubleRight}
                  color={"#a70000"}
                  className="mx-1"
                />
                Times of India - One of the top four colleges in Coimbatore in
                2008
              </p>{" "}
              <p className="ParaStyle">
                <FontAwesomeIcon
                  icon={faAngleDoubleRight}
                  color={"#a70000"}
                  className="mx-1"
                />
                Silicon India Magazine – College of the year 2019
              </p>{" "}
              <p className="ParaStyle">
                <FontAwesomeIcon
                  icon={faAngleDoubleRight}
                  color={"#a70000"}
                  className="mx-1"
                />
                Business Connect – One of the top 10 emerging colleges in India
                2020
              </p>
              <p className="HeadingStyle my-2">
                RVS Institute of Management Studies and Research
              </p>
              <p className="ParaStyle">
                <FontAwesomeIcon
                  icon={faAngleDoubleRight}
                  color={"#a70000"}
                  className="mx-1"
                />
                Business Week - Best college for the year 2008-09.
              </p>
              <p className="ParaStyle">
                <FontAwesomeIcon
                  icon={faAngleDoubleRight}
                  color={"#a70000"}
                  className="mx-1"
                />
                Higher Education Review – Top 100 B-Schools in India 2021
              </p>
              <p className="ParaStyle">
                He’s a constant learner and has completed many executive
                education programs from MIT, Harvard, Kellogg, LSE and IMD
                Business School. Prior to joining RVS, he was the Principal IT
                Consultant for Mantel Corporation in the US from 1997 till 2000
                and consulted for various Fortune 500 companies. He is also an
                active investor and board member of Transform Tech, an Ed Tech
                company which is developing online programs in Data Science,
                Artificial Intelligence, Digital Marketing and IOT.
              </p>
              <p className="ParaStyle my-2">
                {" "}
                He has a BBA (1995) and MBA (1997) from Texas A&M University,  </p>
                <p className="ParaStyle my-2">
                  {" "}
                  MSc. Software Engineering from University of Westminster
                  (2003), UK and PhD in Management from Bharathiar University
                  (2018).
                </p>
            
              <h6>Academic Background</h6>
              <div>
                <p className="ParaStyle ">
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Completed a course in “Digital Marketing Strategies” on April
                  2019 offered by Northwestern, Kellog, USA{" "}
                </p>
              </div>
              <div>
                <p className="ParaStyle">
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Participated in the Faculty Development Program on “Machine
                  Learning with Business Applications” from 8th to 12th October,
                  2018 at Indian Institute of Management (IIM), Bangalore.{" "}
                </p>
              </div>
              <div>
                <p className="ParaStyle">
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Attended a course on “Introduction to Data Science and Big
                  Data Analytics” offered by The London School of Economics and
                  Political Science, August 2018, UK.
                </p>
              </div>
              <div>
                <p className="ParaStyle">
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Successfully completed the executive programme on “Artificial
                  Intelligence: Implications for Business Strategies” on May
                  2018 from Sloan School of Management , Massachusetts Institute
                  of Technology(MIT), Cambridge, MA, USA.
                </p>
              </div>
              <div>
                <p className="ParaStyle">
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Ph.D in Management from Bharathiar University, 21st May,2018
                </p>
              </div>
              <div>
                <p className="ParaStyle">
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Successfully completed the Transform TECH Program from Jan 29
                  – Feb 2, 2018, conducted by IMD & Ecole Polytechnique Federale
                  De Lausanne, Switzerland.
                </p>
              </div>
              <div>
                <p className="ParaStyle">
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Attended a program on “Leading Digital Business
                  Transformation” (LDBT) from June 19th to 23rd 2017 at IMD,
                  Lausanne, Switzerland
                </p>
              </div>
             
              <h6>Industry Experience</h6>
              <div>
                <p className="ParaStyle">
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Business Application Developer at Zales Corporation, Texas,
                  USA during 1997-1998
                </p>
                <p className="ParaStyle">
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Promoter and Senior Consultant at Mantel Corp, USA which is a
                  software consultancy for servicing, Fortune 500 clients like
                  Sprint and AT & T during 1998 – 2002.
                </p>

                <p className="ParaStyle">
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Managing Trustee of RVS Group of Institutions, Coimbatore from
                  2000 – till date. Knowing that the prime aim of a teacher is
                  to tap the talents, mould the mind sets and bring the best out
                  of the students, enveloped in discipline and values, he has
                  encouraged the system of crafting subject matter experts
                  (SMEs), revision of syllabi to suit the emerging trends and
                  formation of sports/culture clubs to hone and fine tune the
                  reflexes
                </p>
                <p className="ParaStyle">
                  In appreciation of his avid interest in enhancing the quality
                  of higher education he has been assigned an active role to
                  play as convenor in the educational panel of the prestigious
                  CII
                </p>
                <p className="ParaStyle">
                  He is a member in the Honours Society of Texas A & M
                  University & Financial Management Association, USA.
                </p>
                <p className="ParaStyle">
                  Realizing the relevance of science and technology to the
                  progress and welfare of mankind, Dr. Senthil Ganesh has
                  focused his attention on the development of science programmes
                  involving Digital specialization courses such as Data Science,
                  Big data analytics, Cloud computing and strategic courses like
                  Data structures and Algorithms. His cherished vision
                  facilitates the promotion of education for the Digital era.
                  Bestowing the best care for the curriculum, the training
                  materials have been crafted by the SMEs trained through MOOC
                  courses from BITs Pilani, IIT Bangalore, RIT – USA and in
                  house Industry experts to ensure the outcome based education
                  for the students.
                </p>
                <p className="ParaStyle">
                  An MoU inked with ‘Livnsense’ Company by Dr. Senthil Ganesh
                  for CBA (Center for Business Analytics) team stands to benefit
                  the staff and students for working on real time projects. His
                  initiation of ‘Industry – Academia’ training classes for the
                  benefit of computer science students has enhance the
                  employability potential by collaboration with RVS Infotech,
                  housed inside the campus.
                </p>
                <p className="ParaStyle">
                  Based on his rich global exposure to the importance of
                  Biomedical research and keen interest to promote research
                  related to cancer, he was instrumental in starting the ‘RVS
                  Institute of Immunology & Cancer Research’. It is certain that
                  this institute is to pave way for augmentation of cancer
                  research in a brighter dimension in South India facilitating
                  fruitful and fabulous findings leading to innovative diagnosis
                  and treatment methods.
                </p>

                <p className="ParaStyle">
                  To be precise Dr. Senthil Ganesh is an ardent endowed with
                  vibrant vision, meticulous academic mission and accomplishing
                  verve to serve, cheer and steer the RVS Conglomerate to the
                  pinnacles of success.
                </p>
                <h6>Industry Experience</h6>
                <p className="ParaStyle">
                  Business Application Developer at Zales Corporation, Texas,
                  USA during 1997 -1998. Promoter and Senior Consultant at
                  Mantel Corp, USA which is a software consultancy for
                  servicing. Fortune 500 clients like Sprint and AT&T during
                  1998 -2002. Managing Trustee for RVS Group of Institutions,
                  Coimbatore from 2000 – till date.
                </p>
                    {/* <h5>Dr.K.Senthil Ganesh, the Managing Trustee</h5>
                    <p>
                      Is the guiding star of RVS Group of Institutions. Under
                      his able guidance the institutions are making rapid
                      strides of progress and are bound to scale new heights.
                      Endowed with academic acumen, admirable administrative
                      abilities and laudable leadership traits, he is now well
                      recognized, much by his focus in the field of education
                      and revered in the company of educational intelligentsia.
                    </p>
                  </Col>
                  <Col lg={3} md={3}>
                    <img
                      src="https://www.rvsimsr.ac.in/images/managing-trustee-mr.k.senthil-ganesh.jpg"
                      className="img-responsive mx-lg-0 mx-md-0 mx-3 my-lg-2 my-md-2"
                      alt="Trustee"
                    ></img>
                  </Col>
                </Row>
              </div>
              <p>
                His profound passion for sustenance of quality in curriculum has
                earned him a reputation among the stakeholders. He consistently
                strives for ushering in “innovations” in diverse disciplines in
                the modern trends of placement scenario.
              </p>
              <h6>Academic Background</h6>
              <div>
                <p>
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Successfully completed a course in “Mastering Digital
                  Marketing: SEM, SEO, Social Media and Beyond” offered by
                  London Business School, UK- September 2020
                </p>
              </div>
              <div>
                <p>
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Has Successfully completed a course in &#39;Business
                  Analytics: From Data to Insights&#39; from The Wharton School,
                  Aresty Institute of Executive Education, University of
                  Pennsylvania, US - April, 2020
                </p>
              </div>
              <div>
                <p>
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Has Successfully completed the Harvard Business School Online
                  Certification Program in &quot;DISRUPTIVE STRATEGY&quot;-
                  August, 2019
                </p>
              </div>
              <div>
                <p>
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Completed a course in “Digital Marketing Strategies” on April
                  2019 offered by Northwestern, Kellogg, USA.
                </p>
              </div>
              <div>
                <p>
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Participated in the Faculty Development Program on “Machine
                  Learning with Business Applications” from 8th to 12th October,
                  2018 at Indian Institute of Management (IIM), Bangalore.
                </p>
              </div>
              <div>
                <p>
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Attended a course on “Introduction to Data Science and Big
                  Data Analytics” offered by The London School of Economics and
                  Political Science, August 2018, UK.
                </p>
              </div>
              <div>
                <p>
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Successfully completed the executive programme on “Artificial
                  Intelligence: Implications for Business Strategies” on May
                  2018 from Sloan School of Management , Massachusetts Institute
                  of Technology(MIT), Cambridge, MA, USA.
                </p>
              </div>
              <div>
                <p>
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Ph.D in Management from Bharathiar University, 21st
                  May,2018.4.
                </p>
              </div>
              <div>
                <p>
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Successfully completed the Transform TECH Program from Jan 29
                  – Feb 2, 2018, conducted by IMD &amp; Ecole Polytechnique
                  Federale De Lausanne, Switzerland.
                </p>
                <p>
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Attended a program on “Leading Digital Business
                  Transformation” (LDBT) from June 19th to June 23rd 2017 at
                  IMD, Lausanne, Switzerland
                </p>
                <p>
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  M.Sc (Software Engineering) from University of Westminister,
                  London, UK, 2004.
                </p>
                <p>
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  BBA(1995) and MBA(1997) degrees from Texas A&amp;M University,
                  USA.
                </p>
              </div>
              <h6>Industry Experience</h6>
              <div>
                <p>
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Business Application Developer at Zales Corporation, Texas,
                  USA during 1997-1998
                </p>
                <p>
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Promoter and Senior Consultant at Mantel Corp, USA which is a
                  software consultancy for servicing, Fortune 500 clients like
                  Sprint and AT & T during 1998 – 2002.
                </p>

                <p>
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    color={"#a70000"}
                    className="mx-1"
                  />
                  Managing Trustee of RVS Group of Institutions, Coimbatore from
                  2000 – till date.
                </p>
                <p>
                  {" "}
                  Knowing that the prime aim of a teacher is to tap the talents,
                  mould the mind sets and bring the best out of the students,
                  enveloped in discipline and values, he has encouraged the
                  system of crafting subject matter experts (SMEs), revision of
                  syllabi to suit the emerging trends and formation of
                  sports/culture clubs to hone and fine tune the reflexes
                </p>
                <p>
                  In appreciation of his avid interest in enhancing the quality
                  of higher education he has been assigned an active role to
                  play as convenor in the educational panel of the prestigious
                  CII
                </p>
                <p>
                  He is a member in the Honours Society of Texas A & M
                  University & Financial Management Association, USA.
                </p>
                <p>
                  Realizing the relevance of science and technology to the
                  progress and welfare of mankind, Dr. Senthil Ganesh has
                  focused his attention on the development of science programmes
                  involving Digital specialization courses such as Data Science,
                  Big data analytics, Cloud computing and strategic courses like
                  Data structures and Algorithms. His cherished vision
                  facilitates the promotion of education for the Digital era.
                  Bestowing the best care for the curriculum, the training
                  materials have been crafted by the SMEs trained through MOOC
                  courses from BITs Pilani, IIT Bangalore, RIT – USA and in
                  house Industry experts to ensure the outcome based education
                  for the students.
                </p>
                <p>
                  An MoU inked with ‘Livnsense’ Company by Dr. Senthil Ganesh
                  for CBA (Center for Business Analytics) team stands to benefit
                  the staff and students for working on real time projects. His
                  initiation of ‘Industry – Academia’ training classes for the
                  benefit of computer science students has enhance the
                  employability potential by collaboration with RVS Infotech,
                  housed inside the campus.
                </p>
                <p>
                  Based on his rich global exposure to the importance of
                  Biomedical research and keen interest to promote research
                  related to cancer, he was instrumental in starting the ‘RVS
                  Institute of Immunology & Cancer Research’. It is certain that
                  this institute is to pave way for augmentation of cancer
                  research in a brighter dimension in South India facilitating
                  fruitful and fabulous findings leading to innovative diagnosis
                  and treatment methods.
                </p>

                <p>
                  To be precise Dr. Senthil Ganesh is an ardent endowed with
                  vibrant vision, meticulous academic mission and accomplishing
                  verve to serve, cheer and steer the RVS Conglomerate to the
                  pinnacles of success.
                </p> */}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
