import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import FacultyMain from "../SideMenu/FacultySideMenu";
import FacultyDetail from "./Visiting.json";

export default function VistingFaculty() {
    const FacultyDetails = FacultyDetail.map((value) => (
        <Row>
            <Col lg={3} md={3} className="m-auto  m-md-auto">
                <img src={value.img} className="facultyimg m-md-auto" alt={`${value.Name}`} />
                <p>
                    <Link
                        className="link"
                        to={{
                            pathname: "/faculty/VisitingFaculty/Visiting",
                            state: { detail: value.id },
                        }}
                    >
                        <p className="FacultyButton m-auto m-md-auto mt-4 mt-lg-3 mt-md-3">
                            View Profile
                        </p>
                    </Link>
                </p>
            </Col>
            <Col lg={9} md={3} className="text-center my-3 m-auto">
                <h5>{value.Name}</h5>
                <span className="fw-bold">{value.Position}</span>
               
                <br />
                <span className="crumbcolor">{value.Teaching}</span>
            </Col>
            <hr />
        </Row>
    ));
    return (
        <Container className='top'>
            <Row>
                <Col lg={3}>
                    <FacultyMain />
                </Col>
                <Col lg={9}>
                    <Card className="p-4">
                    <p>
                              <a href="/">
                                   <span className="mx-1 crumb">
                                        <FontAwesomeIcon icon={faHome} className="mx-1" />
                                   </span>
                              </a>
                              <span className="mx-1">/</span>
                              <a href="/faculty" className="crumb">
                              <span className="mx-1 crumb">
                                   Faculty
                              </span>
                              </a>
                              <span className="mx-1">/</span>
                              <a href="/faculty" className="crumb">
                              <span className="mx-1 crumbcolor">
                                   Visiting Faculty
                              </span>
                              </a>
                         </p>
                        <h4>
                        Visiting Faculty Details
                        </h4>
                        <div>
                            {FacultyDetails}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
