import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faHome } from '@fortawesome/free-solid-svg-icons'
import ResearchMain from '../SideMenu/SideMenu'

export default function GrantsRecevied() {
    return (
        <Container className=" Aboutpage ">
            <Row>
                <Col lg={3}>
                    <ResearchMain />
                </Col>
                <Col lg={9}>
                    <Card className='p-4'>
                        <p>
                            <a href="/">
                                <span className="mx-1 crumb">
                                    <FontAwesomeIcon icon={faHome} className="mx-1" />
                                </span>
                            </a>
                            <span className="mx-1">/</span>
                            <a href="/research/Publications" className="crumb">
                                <span className="mx-1  crumb" href="">
                                    Research Activity
                                </span>
                            </a>
                            <span className="mx-1">/</span>
                            <span className="mx-1  crumbcolor" href="">
                                Grants Received
                            </span>
                        </p>
                        <h4>Grants Received</h4>
                        <div className='GrantsRecevied'>
                            <p>
                                <FontAwesomeIcon
                                    icon={faAngleDoubleRight}
                                    className="mx-3"
                                    color="#A60D1F"
                                />
                                Department of Pharmaceutics received ICMR grant Rs.50,000/- of financial assistance
                                for organizing seminar on Bioinformatics – A Tool for Pharmaceutical Biotechnology
                                and Drug Discovery, 19 th – 20 th June 2018.
                            </p>
                            <p>
                                <FontAwesomeIcon
                                    icon={faAngleDoubleRight}
                                    className="mx-3"
                                    color="#A60D1F"
                                />
                                Head of the institution received Tamilnadu State Pharmacy Council and Tamilnadu
                                Pharmaceutical Welfare Trust grant Rs.25,000/- of financial assistance for organizing
                                Continuing Pharmacy Education Programme (CPEP) for Registered Pharmacist Role of
                                registered Pharmacist in shaping of new health care system 19 th November 2016.
                            </p>
                            <p>
                                <FontAwesomeIcon
                                    icon={faAngleDoubleRight}
                                    className="mx-3"
                                    color="#A60D1F"
                                />
                                Department of Pharmaceutical Analysis have received a sum of Rs.8,00,000/- from
                                AICTE for MODROS during July 2012.
                            </p>
                            <p>
                                <FontAwesomeIcon
                                    icon={faAngleDoubleRight}
                                    className="mx-3"
                                    color="#A60D1F"
                                />
                                Department of Pharmacology received ICMR grant Rs.25,000/- of financial assistance
                                for organizing seminar on Advances in traditional Drug Discovery and Clinical drug
                                Evaluation, 17 th – 18 th February 2011.
                            </p>
                            <p>
                                <FontAwesomeIcon
                                    icon={faAngleDoubleRight}
                                    className="mx-3"
                                    color="#A60D1F"
                                />
                                Department of Pharmacology received AICTE grant Rs.1,00,000/- of financial assistance
                                for organizing seminar on Advances in traditional Drug Discovery and Clinical drug
                                Evaluation, 17 th – 18 th February 2011.
                            </p>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
