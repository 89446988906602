import React from 'react'
import '../../styles.css'
import DownloadBrochure from '../EnquriesForm/DownloadBrochure';

export default function Quicklink() {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <div >
            <h4 style={{ textAlign: 'left' }}>Quick Links</h4>
            <div className='list-unstyled list-group mx-auto m-md-auto' style={{ width: '200px' }}>
                <button onClick={() => setModalShow(true)} className='quick  list-group-item'>Download Brochure</button>
                
                <button  className='quick list-group-item'> <a className='text-decoration-none text-dark' href="https://admissions.rvscas.ac.in/" rel="noreferrer" target="_blank"> Block Your Seats</a></button>
                <button className='quick  list-group-item'>Students Service</button>
                <button className='quick  list-group-item'>  <a className='text-decoration-none text-dark' href="/programme/CourseStructure">Admissions</a></button>
                <button className='quick  list-group-item'> <a className='text-decoration-none text-dark' href="/about/Contact">Contact Us</a></button>
                <button  className='quick list-group-item'> <a className='text-decoration-none text-dark' href="/about/antiragging"
            > Anti Ragging</a></button>
            </div>
            <DownloadBrochure
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div>
    )
}


