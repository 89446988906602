import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome,faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Card, Col, Row, Container } from 'react-bootstrap'
import Programmain from '../../SideMenu/ProgramMenu'


export default function Pharmacology() {
    return (
        <Container className='Aboutpage'>
            <Row >
                <Col lg={3}>
                    <Programmain />
                </Col>
                <Col lg={9}>
                    <Card className='p-4'>
                        <p>
                            <a href='/'>
                                <span className='mx-1 crumb'><FontAwesomeIcon icon={faHome} className='mx-1' /></span>
                            </a>
                            <span className='mx-1'>/</span>
                            <a href='/programme' className='crumb'>
                                <span className='mx-1  crumb' href='/about'>Programme</span>
                            </a>
                            <span className='mx-1'>/</span>
                            <a href='/programme/M.pharm' className='crumb'>
                                <span className='mx-1  crumb' href='/about'>M.Pharm</span>
                            </a>
                            <span className='mx-1'>/</span>
                            <span className='mx-1  crumbcolor' href=''>Pharmacology</span>
                        </p>
                        <h4>Pharmacology</h4>
                       <div className="Pharm">
                       <p>
                            The Department of Pharmacology at RVSCOPS , is involved in teaching
                            Pharmacology to B.Pharm,M.Pharm and Pharm.D graduates. The department is well
                            equipped, having qualified, experienced faculty and is involved in research.
                        </p>
                        <p>
                        Various research projects of Pre-clinical and animal studies are carried out in the
department. The well-established government approved animal house is maintained and used
for various animal studies.
                        </p>
                        <p>
                        The UG, PG Students and research faculty of Pharmacology department have
published papers in various national and International scientific journals and are
encouraged to present in conferences.
                        </p>
                        <p>
                        The Department has conducted several Workshop and Continuing education
programme for community pharmacists funded by registered authorities and funding bodies.
Students research Council provides the support and facilities to develop the research skill.
                        </p>
                        <Row>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/mpc1.png'className='img-fluid' alt='b.form1'></img></Col>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/mpc2.png'className='img-fluid d-none d-md-block' alt='b.form1'></img></Col>
                         </Row>
                       </div>
                        <h6 className=' fs-5'>Key Features</h6>
                        <div className="MPharm">
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"} />
                                Training of undergraduates to make them competent in academics, research and industry.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"} />
                                Guiding research scholars with Well-qualified and experienced faculty
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"} />
                                Well sophisticated department sectional library with e-books, Internet connection and Copier facilities.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"} />
                                Smart class for modern teaching and learning process for  seminar and journal Club presentation.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"} />
                                Research activities such as Pre– Clinical studies are carried out as per GCP guidelines.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"} />
                                Well established Animal house for research purpose as per GLP/CPCSEA guidelines and approved.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"} />
                                Consultancy service is available with reasonable fee.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"} />
                                National level seminar and CMEs conducted every year for filling the education gap in the department.
                            </p>
                        </div>



                        
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
