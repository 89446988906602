import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Infrastructure from './Infrastructure';
import Academic from './Academic';
import OtherFacilities from './OtherFacilities';
export default function InfrastructurePage() {
     return (
          <Router>
               <Switch>
                    <Route exact path={"/about/Infrastructure"} component={Infrastructure} />
                    <Route exact path={"/about/Infrastructure/Academic"} component={Academic} />
                    <Route exact path={"/about/Infrastructure/OtherFacilities"} component={OtherFacilities} />
               </Switch>
          </Router>
     )
}
