import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import ResearchMain from '../SideMenu/SideMenu'

export default function ResearchProject() {
     return (
         <Container className='Aboutpage'>
              <Row>
                   <Col lg={3}>
                        <ResearchMain/>
                   </Col>
                   <Col lg={9}>
                        <Card className="p-4">
                             <p>
                                  <a href="/">
                                       <span className="mx-1 crumb">
                                       <FontAwesomeIcon icon={faHome} />
                                       </span>
                                  </a>
                                  <span>/</span>
                                  <a href="/research/Publications" className="crumb">
                                       <span className="mx-1 crumb">
                                            Research Activity
                                       </span>
                                  </a>
                                  <span>/</span>
                                  <span className="mx-1 crumbcolor">
                                        Research Project
                                  </span>
                             </p>
                             <h4>
                                  Research Project
                             </h4>
                             

                        </Card>
                   </Col>
              </Row>
         </Container>
     )
}
