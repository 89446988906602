import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Card, Col, Row, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AboutMain from '../SideMenu/AboutMenu'
import Enquries from '../../HomePage/EnquriesForm/Enquries'


export default function Infrastructure() {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <Container className="Aboutpage">
            <Row>
                <Col lg={3} >
                    <AboutMain />
                </Col>
                <Col lg={9} md={12} className="mt-md-5 mt-lg-auto">
                    <Card className='p-4'>
                        <p>
                            <a href='/'>
                                <span className='mx-1 crumb'><FontAwesomeIcon icon={faHome} className='mx-1' /></span>
                            </a>
                            <span className='mx-1'>/</span>
                            <a href='/about' className='crumb'>
                                <span className='mx-1  crumb' >About Us</span>
                            </a>
                            <span className='mx-1'>/</span>
                            <span className='mx-1  crumbcolor' href='/'>Infrastructure</span>

                        </p>
                        <h4>Infrastructure</h4>
                        <p>Facilities which support modern Pharmaceutical study and research.</p>
                        <Row className='my-5'>
                            <Col lg={6} md={6} >
                                <h6>Academic</h6>
                                <p>Well established infrastructure matters for learning and education infrastructure of evidence high
quality better instruction, student outcomes, reduced dropouts.</p>
                                <Link to={'/about/Infrastructure/Academic'} className='text-decoration-none'>

                                    <img className='img img-fluid' src='/images/button/academic.png' alt='Academic' />
                                </Link>
                            </Col>
                            <Col lg={6} md={6}>
                                <h6>Other Facilities</h6>
                                <p  className=''>Comprise a campus functional support system to research, sponsored project etc and provide
various facilities to create an exceptional atmosphere for students and faculty in a great many
ways.</p>
                                <Link to={'/about/Infrastructure/OtherFacilities'} className='text-decoration-none'>
                                    <img className='img img-fluid' src='/images/button/Other-Facilities.png' alt='OtherFacilities' />
                                </Link>

                            </Col>
                        </Row>

                        <p onClick={() => setModalShow(true)} className='brochurebutton m-auto mx-auto m-md-auto'>Talk to the Expert</p>
                    </Card>
                </Col>

            </Row>
            <Enquries
            show={modalShow}
            onHide={() => setModalShow(false)}
            />
        </Container>
    )
}

