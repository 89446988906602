exports.styles={
    footer:{
        color:'#FFAE00',
        fontFamily: 'inherit',
        fontWeight:'lighter',
        
    },
    crumbs:{
        color:'black',
        textDecoration:'none'
    },
    facultycard:{
        backgroundColor:"#747474"
    },
    SidebarContent:{
    background:"#8e0002",
    color:"white",
    fontSize:"15px"
    },
    
}