import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

// HOME PAGE
import Header from './HomePage/Header/Header'
import Header2 from './HomePage/Sidebar/SliderMenu'
import CarouselImage from './HomePage/CarouselImages/Carousel'
import JobOpportunities from './HomePage/JobOpportunities/JobOpportunities'
//ABOUT PAGE
import AboutPage from './Aboutpage/AboutPage'
//PROGRAMME PAGE
import ProgrammesPage from './ProgrammesPage/ProgrammePage'
//FACULTY PAGE
import FacultyPage from './FacultyPage/FacultyPage'
//Page not Found
//import PagenotFound from './PagenotFound/PagenotFound'
//RESEARCH ACTIVITY PAGE
import ResearchActivityPage from './ResearchActivity/ResearchActivityPage'
//FOOTER PAGE
import Footer from './HomePage/Footer/Footer'


export default function Screens() {
     return (
          <Router>
               
             
                  <div>
                       <Header/>
                  </div>

                    {/* <Route path={'/'} component={Header} /> */}
                    <Route path={'/'} component={Header2} />
                    <Route exact path={"/"} component={CarouselImage} />
                    <Route exact path={"/JobOpportunities"} component={JobOpportunities} />

                    {/* About Page */}

                    <Route path="/about" component={AboutPage} />

                    {/* Programmes Page */}

                    <Route path={"/programme"} component={ProgrammesPage} />

                    {/*Faculty Page */}

                    <Route path={"/faculty"} component={FacultyPage} />

                    {/*Research Activity Page */}

                    <Route path={"/research"} component={ResearchActivityPage} />

                  

                    <Footer/>
                         
             
          </Router>
     )
}
