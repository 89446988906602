import React from 'react'
import { Card, Col, Row ,Container} from 'react-bootstrap'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faHome} from '@fortawesome/free-solid-svg-icons'
import AboutMain from '../SideMenu/AboutMenu'
import Testimonial from './TestimonialsAlumini.json'


export default function Testimonails() {

    const Bpharm=Testimonial.filter(data=>data.Course==='B.Pharm').map(value=>
      <Row className='p-1 mx-auto'>
          <Col lg={3} md={3}>
              <img className="Testimonialsimg my-3 " src={value.img} alt='AluminiPhotos'  />
              </Col>
              <Col lg={9} md={9}>
                  <h5 className="TestimonialsName">{value.Name}</h5>
                  <p className="crumbcolor TestimonalsPara">{value.Position}</p>
                  <p className="TestimonalsPara">{value.About}</p>
              </Col><hr/>
      </Row>
        )
        const Mpharm=Testimonial.filter(data=>data.Course==='M.Pharm').map(value=>
            <Row className='p-1'>
                <Col lg={3} md={3}>
                    <img className="Testimonialsimg my-3" src={value.img} alt='AluminiPhotos'  />
                    </Col>
                    <Col lg={9} md={9}>
                        <h5 className="TestimonialsName">{value.Name}</h5>
                        <p className="crumbcolor TestimonalsPara">{value.Position}</p>
                        <p className="TestimonalsPara">{value.About}</p>
                    </Col><hr/>
            </Row>
              )
              const PharmD=Testimonial.filter(data=>data.Course==='Pharm.D').map(value=>
                <Row className='p-1'>
                    <Col lg={3} md={3}>
                        <img className="Testimonialsimg my-3" src={value.img} alt='AluminiPhotos'  />
                        </Col>
                        <Col lg={9} md={9}>
                            <h5>{value.Name}</h5>
                            <p className="crumbcolor">{value.Position}</p>
                            <p>{value.About}</p>
                        </Col><hr/>
                </Row>
                  )
                  const PhD=Testimonial.filter(data=>data.Course==='Ph.D').map(value=>
                    <Row className='p-1'>
                        <Col lg={3} md={3}>
                            <img className="Testimonialsimg my-3" src={value.img} alt='AluminiPhotos'  />
                            </Col>
                            <Col lg={9} md={9}>
                                <h5>{value.Name}</h5>
                                <p className="crumbcolor">{value.Position}</p>
                                <p>{value.About}</p>
                            </Col><hr/>
                    </Row>
                      )

    return (
        <Container className="Aboutpage">
            <Row>
                <Col lg={3}>
                    <AboutMain/>
                </Col>
            <Col lg={9} md={12} >
            <Card className='p-4 '>
               <p >
                <a  href='/' className='crumb'><span className='mx-1 crumb'><FontAwesomeIcon icon={faHome}  className='mx-1 '/></span></a>
                <span className='mx-1'>/</span>
                <a href='/about' className='crumb'>
                <span className='mx-1  crumb' href='/about'>About Us</span>
                </a>
                <span className='mx-1'>/</span>
                <span className='mx-1  crumbcolor' >Testimonials</span></p>
                <h4>Testimonials</h4>
                <h5  className='my-3 mx-1'>B.Pharm</h5>
                {Bpharm}
                <h5  className='my-3 mx-1'>M.Pharm</h5>
               {Mpharm}
               <h5  className='my-3 mx-1'>Pharm.D</h5>
               {PharmD}
               <h5  className='my-3 mx-1'>Ph.D</h5>
               {PhD}
               
               
            </Card>
            
            </Col>
            </Row>
            </Container>
    )
}


