import React, { useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { styles } from "../../styles";

export default function SidebarMenu({ Click }) {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  
  return (
    <div
      className="SideNav"
      style={
        Click === "entering"
          ? { animation: "moveSidebar .3s forwards" }
          : Click === "entered"
          ? { transform: "translateX(-0px)" }
          : { animation: "moveSidebar .3s  reverse forwards" }
      }
    >
      <Accordion>
        <Card>
          <Accordion.Toggle
            className="SidebarContent"
            as={Card.Header}
            eventKey="0"
            style={styles.SidebarContent}
          >
            <div onClick={() => setOpen(!open)}>
              <div className="text-start">
                ABOUT US{" "}
                <span className="" style={{marginLeft:"130px"}}>
                 {!open?<FontAwesomeIcon icon={faAngleDown} />:<FontAwesomeIcon icon={faAngleUp}/>}
                </span>{" "}
              </div>
            </div>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="0" className="SidebarContentView">
            <Card.Body className="SidebarSubMenu">
              <div>
                <Row>
                  <h5>
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      className="mx-2"
                      color={"white"}
                    />
                    <a href=" /about">The Management</a>
                  </h5>

                  <h5>
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      className="mx-2 "
                      color={"white"}
                    />
                    <a href="/about/Testimonials">Testimonials</a>
                  </h5>
                  <h5 className="sub-menu-title">
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      className="mx-2"
                      color={"white"}
                    />
                    <a href="/about/Infrastructure">Infrastructure</a>
                  </h5>
                  <Col>
                    <a href="/about/Infrastructure/Academic">
                      <h6 className="mx-4 mx-md-5">
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="mx-1 mx-md-2"
                          color={"white"}
                        />
                        Academic
                      </h6>
                    </a>
                  </Col>
                  <Col>
                    <a href="/about/Infrastructure/OtherFacilities">
                      <h6 className="mx-4 mx-md-5">
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="mx-1 mx-md-2"
                          color={"white"}
                        />
                        Other Facilities
                      </h6>
                    </a>
                  </Col>

                  <h5 className="sub-menu-title">
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      className="mx-2"
                      color={"white"}
                    />
                    <a href="/about/Placements">Placement</a>
                  </h5>
                  <h5 className="sub-menu-title">
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      className="mx-2"
                      color={"white"}
                    />
                    <a href="/about/StudentsActivites">Students Activities</a>
                  </h5>
                  <Col className="mx-4 mx-md-5">
                    <a href="!#">
                      <h6>
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="mx-2"
                          color={"white"}
                        />
                        <a href="/about/StudentsActivites/Curriculum">
                          Curriculum
                        </a>
                      </h6>
                    </a>
                  </Col>
                  <Col className="mx-4 mx-md-5">
                    <a href="!#">
                      <h6>
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="mx-2"
                          color={"white"}
                        />
                        <a href="/about/StudentsActivites/Co-Curricular">
                          Co-Curricular
                        </a>
                      </h6>
                    </a>
                  </Col>
                  <Col className="mx-4 mx-md-5">
                    <a href="!#">
                      <h6>
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="mx-2"
                          color={"white"}
                        />
                        <a href="/about/StudentsActivites/Extra-Curricular">
                          Extra-Curricular
                        </a>
                      </h6>
                    </a>
                  </Col>
                  <Col className="mx-4 mx-md-5">
                    <a href="!#">
                      <h6>
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="mx-2"
                          color={"white"}
                        />
                        <a href="/about/StudentsActivites/Achivements">
                          Achievements
                        </a>
                      </h6>
                    </a>
                  </Col>
                  <h5 className="sub-menu-title">
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      className="mx-2"
                      color={"white"}
                    />
                    <a href="/about/antiragging">Anti Ragging</a>
                  </h5>
                  <h5 className="sub-menu-title">
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      className="mx-2"
                      color={"white"}
                    />
                    <a href="about/Contact">Contact Us</a>
                  </h5>
                  <h5 className="sub-menu-title">
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      className="mx-2"
                      color={"white"}
                    />
                    <a href="/about/EventsCalender">Event Calender</a>
                  </h5>
                </Row>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle
            className="SidebarContent"
            as={Card.Header}
            eventKey="1"
            style={styles.SidebarContent}
          >
           <div onClick={() => setOpen1(!open1)}>
              <div className="text-start">
              PROGRAMMES{" "}
                <span className="" style={{marginLeft:"100px"}}
                 
                  
                >
                 {!open1?<FontAwesomeIcon icon={faAngleDown} />:<FontAwesomeIcon icon={faAngleUp}/>}
                </span>{" "}
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1" className="SidebarContentView">
            <Card.Body className="SidebarSubMenu">
              <div>
                <Row>
                  <h4>
                    <a href="/programme">Programmes</a>
                  </h4>
                  <h5 className="sub-menu-title">
                    <a href="/programme/B.pharm">
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="mx-2"
                        color={"white"}
                      />
                      B.Pharm
                    </a>
                  </h5>
                  <h5 className="sub-menu-title">
                    <a href="/programme/M.pharm">
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="mx-2"
                        color={"white"}
                      />
                      M.Pharm
                    </a>
                  </h5>
                  <Col>
                    <a href="/programme/M.pharm/Pharmaceutics">
                      <h6 className="mx-2 mx-md-3 ">
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="mx-2"
                          color={"white"}
                        />
                        Pharmaceutics
                      </h6>
                    </a>
                  </Col>
                  <Col>
                    <a href="/programme/M.pharm/PharmaceuticalAnalysis">
                      <h6 className="mx-2 mx-md-3">
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="mx-2"
                          color={"white"}
                        />
                        Pharmaceutical Analysis
                      </h6>
                    </a>
                  </Col>
                  <Col>
                    <a href="/programme/M.pharm/Pharmacology">
                      <h6 className="mx-2 mx-md-3">
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="mx-2"
                          color={"white"}
                        />
                        Pharmacology
                      </h6>
                    </a>
                  </Col>
                  <a href="/programme/Pharm.D">
                    <h5 className="sub-menu-title">
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="mx-2"
                        color={"white"}
                      />
                      Pharm.D
                    </h5>
                  </a>
                  <a href="/programme/Ph.D">
                    <h5 className="sub-menu-title">
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="mx-2"
                        color={"white"}
                      />
                      Ph.D
                    </h5>
                  </a>
                  <a href="/programme/CourseStructure">
                    <h5 className="sub-menu-title">
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="mx-2"
                        color={"white"}
                      />
                      Course Structure
                    </h5>
                  </a>
                </Row>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle
            className="SidebarContent"
            as={Card.Header}
            eventKey="2"
            style={styles.SidebarContent}
          >
           <div onClick={() => setOpen2(!open2)}>
              <div className="text-start">
              FACULTY{" "}
                <span className="" style={{marginLeft:"140px"}}>
                 {!open2?<FontAwesomeIcon icon={faAngleDown} />:<FontAwesomeIcon icon={faAngleUp}/>}
                </span>{" "}
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2" className="SidebarContentView">
            <Card.Body className="SidebarSubMenu">
              <div>
                <Row>
                  <h5 className="sub-menu-title">
                    <a href="/faculty">
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="mx-2"
                        color={"white"}
                      />{" "}
                      Faculty{" "}
                    </a>
                  </h5>
                  <h5 className="sub-menu-title">
                    <a href="/faculty/VisitingFaculty">
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="mx-2"
                        color={"white"}
                      />
                      Visiting Faculty
                    </a>
                  </h5>
                </Row>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle
            className="SidebarContent"
            as={Card.Header}
            eventKey="3"
            style={styles.SidebarContent}
          >
            
            <div onClick={() => setOpen3(!open3)}>
              <div className="text-start">
              RESEARCH ACTIVITIES{" "}
                <span className="" style={{marginLeft:"48px"}}>
                 {!open3?<FontAwesomeIcon icon={faAngleDown} />:<FontAwesomeIcon icon={faAngleUp}/>}
                </span>{" "}
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3" className="SidebarContentView">
            <Card.Body className="SidebarSubMenu">
              <div>
                <Row>
                  <h5 className="sub-menu-title">
                    <a href="/research/Publications">
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="mx-2"
                        color={"white"}
                      />
                      Publications
                    </a>
                  </h5>
                  <h5 className="sub-menu-title">
                    <a href="/research/GrantsRecevied">
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="mx-2"
                        color={"white"}
                      />
                      Grants Received
                    </a>
                  </h5>

                  <h5 className="sub-menu-title">
                    <a href="/research/AnimalHouse">
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="mx-2"
                        color={"white"}
                      />
                      Animal House
                    </a>
                  </h5>
                  <h5 className="sub-menu-title">
                    <a href="/research/MedicinalGarden">
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="mx-2"
                        color={"white"}
                      />
                      Medicinal Garden
                    </a>
                  </h5>
                  <h5 className="sub-menu-title">
                    <a href="/research/CommercialActivity">
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="mx-2"
                        color={"white"}
                      />
                      Commercial Activities
                    </a>
                  </h5>
                </Row>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}
