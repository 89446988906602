import React from 'react'
import { Card, Col, Row, Container, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faHome } from '@fortawesome/free-solid-svg-icons'
import AboutMain from '../SideMenu/AboutMenu'


export default function Placements() {

  return (
    <Container className="Aboutpage">
      <Row>
        <Col lg={3}>
          <AboutMain />
        </Col>
        <Col lg={9}>

          <Card className="p-4">
            <p>
              <a href='/'>
                <span className="mx-1 crumb">
                  <FontAwesomeIcon icon={faHome} className="mx-1 " />
                </span>
              </a>
              <span className="mx-1">/</span>
              <a href="/about" className='crumb'>
                <span className="mx-1  crumb" >
                  About Us
                </span>
              </a>
              <span className="mx-1">/</span>
              <span className="mx-1  crumbcolor">Placements</span>
            </p>
            <div className="Placement">
            <h4>Placements</h4>
            <p className='mx-3 my-3'>
              We, RVSCOPS, go beyond just education and be a facilitator of positive growth and
              inspiring environment, redefine the graduate’s readiness for employment, entrepreneurship and
              transform potential. Exploring the potential is a career readiness program that inspires to take
              control of their future by crafting life and career, that is, personally customized to them.
              We prepare the graduates for a successful career as a pharmacist in community, hospital settings
              or qualified person in manufacturing/analytical or formulation sector of Pharmaceuticals with
              professional development.
            </p>
            <h5>CAREER ENHANCING SKILLS</h5>
            <p>
              The graduates will be trained to develop substantial transferable skills that are valued in a range
              of work environment including
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Communication
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Organizational and research skill
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Writing reports
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Performing laboratory
            </p>
            <p className='my-2'>
              The college placement cell has student friendly, employability service, which can help on how to
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Apply for jobs
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Write good CV
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Perform well in Interviews
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Personality development etc.,
            </p>


            <h5 className='my-3'>CAREERS</h5>
            <p>
              Our graduates are qualified to work in all branches of the profession such as
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Hospitals as hospital/clinical pharmacists
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Pharmacovigilance
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Primary care
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Industry
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Academia
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              R & D
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Government Drug Administration Board
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              The Medicine Safety Managers etc.,
            </p>

            <p className='my-2'>
              The pay packages depend upon the qualification, location and experience of the candidate. In
              India, starting salary of the Pharmacy graduate ranges from 25 to 30K per month and an average
              of 3,50,000 per year. In U.S. the salary would be in the range of 40000 US dollars and 1,20,000
              US dollars annually after one year of experience.
            </p>
            <h5>OUR MAJOR RECRUITERS & TRAINERS</h5>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Cognizant Technology Solutions
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              PSG Hospitals
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              KMCH Hospitals
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Visionary RCM
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Apollo Hospitals & Chain of Pharmacies
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Accenture India
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Medreich Pharma
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Sevenhills college of pharmacy
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              KG Hospitals
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Oviya med safe
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Doctor Thangs laboratories
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Micro Therapeutics
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Pharma Fabricon and more
            </p>

            <h5 className='my-3'>GENERAL PLACEMENT AREA RECORD DETAILS</h5>

            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Pharma manufacturing industries - 20%
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Pharmacovigilance and Clinical trials – 15%
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Pharma marketing – 10%
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Hospital/Clinical & Community Pharmacist – 10%
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Higher Studies - 25%
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Entrepreneur -10%
            </p>
            <p>
              <FontAwesomeIcon icon={faAngleDoubleRight} color={'#a70000'} className='mx-2' />
              Abroad – 10%
            </p>

            <h5 className='my-3'>
              SOME OF THE SELECTED CANDIDATES DETAILS
            </h5>

            <Table bordered responsive='sm'  className='PlacementTable text-center mx-auto'>
              <thead>
                <tr>

                  <th>Name of the student</th>
                  <th>Package</th>
                  <th>Company</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Praveena R</td>
                  <td>Rs.269496/=PER ANNUM</td>
                  <td>Cognizant Technology Solutions</td>
                </tr>
                <tr>
                  <td>Sreelakshmi S</td>
                  <td>Rs.269496/=PER ANNUM</td>
                  <td>Cognizant Technology Solutions</td>
                </tr>
                <tr>
                  <td>Nijanthan G</td>
                  <td>Rs.269496/=PER ANNUM</td>
                  <td>Cognizant Technology Solutions</td>
                </tr>
              </tbody>
            </Table>

            <h4 className='my-3'>Contact us</h4>
            <span>Dr.T.Akelesh.,M.Pharm.,Ph.D</span><br/>
            <span>Professor</span><br/>
            <span>Contact : 9790015105</span><br/>
            <span>Email : <span className='email'>akelesh_pharm@rvsgroup.com</span></span>
            </div>




          </Card>
        </Col>
      </Row>
    </Container>
  )
}



