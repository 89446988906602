import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Card, Col, Container, Row,Table } from 'react-bootstrap'
import Programmain from '../SideMenu/ProgramMenu'

export default function CourseStructure() {
    return (
        <Container className='Aboutpage'>
            <Row>
                <Col lg={3}>
                    <Programmain />
                </Col>
                <Col lg={9}>
                    <Card className='p-4'>
                        <p>
                            <a href='/'><span className='mx-1 crumb'><FontAwesomeIcon icon={faHome} className='mx-1' /></span></a>
                            <span className='mx-1'>/</span>
                            <a href='/programme' className='crumb'>
                                <span className='mx-1  crumb' >Programme</span>
                            </a>
                            <span className='mx-1'>/</span>
                            <span className='mx-1  crumbcolor' href=''>Course Structure</span></p>
                        <h4>Course Structure</h4>
                        <Table responsive bordered>

                            <tbody>
                                <tr>

                                    <th className='text-center'>Course Name</th>
                                    <th className='text-center'>Duration</th>

                                </tr>
                                <tr>

                                    <th width="50%" className='text-center'>B.Pharm</th>
                                    <td className='text-center'>4 years – Semester Pattern</td>

                                </tr>
                                <tr>

                                    <th className='text-center'>Pharm.D</th>
                                    <td className='text-center'>6 years – Annual Pattern</td>

                                </tr>
                                <tr>

                                    <th className='text-center'>M.Pharm</th>
                                    <td className='text-center'>2 years – Semester Pattern</td>

                                </tr>

                                <tr>

                                    <th className='text-center'>Ph.D</th>
                                    <td className='text-center'>Full time 3 years; Part time 4 years</td>

                                </tr>

                            </tbody>
                        </Table>
                        <h5 className='crumb' >Eligibility</h5>
                        <p className='mx-3 p-2'>
                            For B.Pharm & Pharm.D Courses, the candidate should be minimum of 17 years of age at
                            the time of admission (or) complete 17 years of year before November of the year of admission
                            with following qualification.
                        </p >
                        <a href='/programme/B.Pharm' className='crumb'>
                            <h5 className='crumb'>B.Pharm Course</h5>
                        </a>
                        <p className='mx-3 p-2'>
                            Candidates must pass 10+2 with physics, chemistry as compulsory subject along with any
                            one as optional like mathematics, biology or computer science with 50% marks in qualifying
                            examination in academic stream (or) any other equivalent qualification as approved by
                            university.
                        </p>
                        <a href='/programme/M.Pharm' className='crumb'>
                            <h5 className='crumb'>M.Pharm Course</h5>
                        </a>
                        <p className='mx-3 p-2'>
                            A pass in B.Pharm degree from any institution approved by Pharmacy Council of India
                            and University Grand Commission recognized university.
                        </p>
                        <a href='/programme/Pharm.D' className='crumb'>
                            <h5 className='crumb'>Pharm.D Course</h5>
                        </a>
                        <p className='mx-3 p-2'>
                            Candidates must pass 10+2 with physics, chemistry as compulsory subject along with any
                            one as optional like mathematics, biology or computer science with 50% marks in qualifying
                            examination in academic stream (or) any other equivalent qualification as approved by
                            university.
                        </p>
                        <a href='/programme/Ph.D' className='crumb'>
                            <h5 className='crumb'>Ph.D Course</h5>
                        </a>
                        <p className='mx-3 p-2'>
                            It is a research programme in pharmacy field available in pharmaceutical
                            chemistry/Analysis, Pharmaceutics, Pharmacology and Pharmacognosy specialization.
                            Candidates pursue this course after completing M.Pharm form recognized institution.
                        </p>

                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
