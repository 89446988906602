import React from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";



function Programmain() {
  const Color=(Colors)=>{
    if(window.location.pathname===Colors){
      const style={
        textDecoration:"underline",
        color:"#7f1010"
      }
      return style
    }
  }

  const location = useLocation(); // To get the current route
  const Specialization = () => {
    //If condition to check whether the path name is /about/specializations
   
    if (
      location.pathname === "/programme/" ||
      location.pathname === "/programme/B.pharm" ||
      location.pathname === "/programme/M.pharm" ||
      location.pathname === "/programme/M.pharm/Pharmaceutics" ||
      location.pathname === "/programme/M.pharm/PharmaceuticalAnalysis" ||
      location.pathname === "/programme/M.pharm/Pharmacology" ||
      location.pathname === "/programme/Pharm.D" ||
      location.pathname === "/programme/Ph.D"
    ) {
      return (
        <Card>
          {" "}
          <ul className=" list-unstyled p-4">
            <a href="/programme/B.pharm" className="text-decoration-none">
              <li style={Color("/programme/B.pharm")}>
                <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                B.Pharm
              </li>
            </a>
            <a href="/programme/M.pharm" className="text-decoration-none">
              <li style={
                
                Color("/programme/M.pharm") ||
                Color("/programme/M.pharm/Pharmaceutics") ||
                Color("/programme/M.pharm/PharmaceuticalAnalysis") ||
                Color("/programme/M.pharm/Pharmacology")
                }>
                <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                M.Pharm
              </li>
            </a>
            {mpharm}
            <a href="/programme/Pharm.D" className="text-decoration-none">
              <li style={Color("/programme/Pharm.D")}>
                <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                Pharm.D
              </li>
            </a>
            <a href="/programme/Ph.D" className="text-decoration-none">
              <li style={Color("/programme/Ph.D")}>
                <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                Ph.D
              </li>
            </a>
          </ul>
        </Card>
      );
    }
  };

  const Mpharm = () => {
    //If condition to check whether the path name is /about/specializations
    if (
      location.pathname === "/programme/M.pharm" ||
      location.pathname === "/programme/M.pharm/Pharmaceutics" ||
      location.pathname === "/programme/M.pharm/PharmaceuticalAnalysis" ||
      location.pathname === "/programme/M.pharm/Pharmacology"
    ) {
      return (
        <div className='m-1 '>
          {" "}
          <ul className=" list-unstyled p-1">
            <a href="/programme/M.pharm/Pharmaceutics" className="text-decoration-none">
              <li style={Color("/programme/M.pharm/Pharmaceutics")}>
                <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                Pharmaceutics
              </li>
            </a>
            <a href="/programme/M.pharm/PharmaceuticalAnalysis" className="text-decoration-none">
              <li style={Color("/programme/M.pharm/PharmaceuticalAnalysis")}>
                <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                Pharmaceutical Analysis
              </li>
            </a>

            <a href="/programme/M.pharm/Pharmacology" className="text-decoration-none">
              <li style={Color("/programme/M.pharm/Pharmacology")}>
                <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                Pharmacology
              </li>
            </a>

          </ul>
        </div>
      );
    }
  };
  const mpharm = Mpharm();

  const specialization = Specialization(); // card variable holds the html elements to render the content under Specialization sub menu
  // card variable holds the html elements to render the content under Specialization sub menu

  return (
    <Card className="aboutmenu bg-light bg-opacity-100 d-none d-sm-block  d-lg-block d-md-none">
      <ul className="list-unstyled submenu text-start">
        <a href="/programme/" className="text-decoration-none">
          <li className="border p-2 " style={
            
            Color("/programme/")||
            Color("/programme/B.pharm")||
            Color("/programme/M.pharm")||
            Color("/programme/M.pharm/Pharmaceutics") ||
            Color("/programme/M.pharm/PharmaceuticalAnalysis") ||
            Color("/programme/M.pharm/Pharmacology") ||
            Color("/programme/Pharm.D") ||
            Color("/programme/Ph.D")
            
            }>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1" />
            Programme
          </li>
        </a>
        {specialization}
        <a href="/programme/CourseStructure" className="text-decoration-none">
          <li className="border p-2" style={Color('/programme/CourseStructure')}>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1" />
            CourseStructure
          </li>
        </a>
      </ul>
    </Card>
  );
}

export default Programmain;
