import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row, Container, Table } from "react-bootstrap";
import AboutMain from "../../SideMenu/AboutMenu";
import Enquries from "../../../HomePage/EnquriesForm/Enquries";

export default function Achivements() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <Container className="Aboutpage">
      <Row>
        <Col lg={3}>
          <AboutMain />
        </Col>
        <Col lg={9}>
          <Card className="p-4">
            <p>
              <a href="/">
                <span className="mx-1 crumb">
                  <FontAwesomeIcon icon={faHome} className="mx-1" />
                </span>
              </a>
              <span className="mx-1">/</span>
              <a href="/about" className="crumb">
                <span className="mx-1  crumb">About Us</span>
              </a>
              <span className="mx-1">/</span>
              <a href="/about/StudentsActivites" className="crumb">
                <span className="mx-1  crumb">Students Activites</span>
              </a>
              <span className="mx-1">/</span>
              <span className="mx-1  crumbcolor">Achievements</span>
            </p>
            <h4>Achievements</h4>
            <h6>GPAT (Graduate Pharmacy Aptitude Test)</h6>
            <p>
              The Graduate Pharmacy Aptitude Test (GPAT) is an annual all‑India
              examination conducted by National Testing Agency on computer based
              test.
            </p>
            <Table bordered>
              <thead>
                <tr className="text-center">
                  <th>Year</th>
                  <th>Name of the qualified students </th>
                </tr>
              </thead>
              <tbody className="text-center">
                <tr>
                  <td>2015</td>
                  <td>Abdul Azeez PH </td>
                </tr>
                <tr>
                  <td>2016</td>
                  <td>Mufeeda.T</td>
                </tr>
                <tr>
                  <td rowspan="2">2017</td>
                  <td>Beulah.V</td>
                </tr>
                <tr>
                  <td>Suren Kumar.V</td>
                </tr>
                <tr>
                  <td rowspan="3">2018</td>
                  <td>Nazzneen.MV</td>
                </tr>
                <tr>
                  <td>Preeyanga.K</td>
                </tr>
                <tr>
                  <td>Hariprasad.R</td>
                </tr>
                <tr>
                  <td rowspan="2">2020</td>
                  <td>Manigandan.S</td>
                </tr>
                <tr>
                  <td>Navaneetha Krishnan.S</td>
                </tr>
                <tr>
                  <td rowspan="5">2021</td>
                  <td>Mohanasankar.V</td>
                </tr>
                <tr>
                  <td>Vijaya Sangeetha.G</td>
                </tr>
                <tr>
                  <td>Ajeth.G</td>
                </tr>
                <tr>
                  <td>Moganthan.R</td>
                </tr>
                <tr>
                  <td>Suresh.G</td>
                </tr>
              </tbody>
            </Table>
              <h5> Awards</h5>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>NAME OF THE STUDENT</th>
                  <th>YEAR &amp; COURSE</th>
                  <th>NAME OF THE AWARD</th>
                  <th>TRUST / AGENCY</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>K.CHANDRALEKA</td>
                  <td>II-M.Pharm (Pharmaceutics)</td>
                  <td>P.G Pharmacy –Research Fellowship Award</td>
                  <td>
                    Tamilnadu Pharmaceutical Sciences Welfare Trust,
                    Chennai,Tamilnadu
                  </td>
                </tr>
              </tbody>
            </Table>
            <p
             onClick={() => setModalShow(true)} className="brochurebutton m-md-auto m-auto">
              Talk to the Expert
            </p>
          </Card>
        </Col>
      </Row>
      <Enquries show={modalShow} onHide={() => setModalShow(false)} />
    </Container>
  );
}
