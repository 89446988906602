import React from 'react'
import {Card, Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import AboutMain from '../../SideMenu/AboutMenu'

export default function CoCurricular() {
    return (
        <Container className="Aboutpage">
            <Row>
                <Col lg={3}>
                    <AboutMain />
                </Col>

                <Col lg={9}>
                    <Card className="p-4">

                        <p >
                            <a href='/' className='crumb'><span className='mx-1 crumb'><FontAwesomeIcon icon={faHome} className='mx-1 ' /></span></a>
                            <span className='mx-1'>/</span>
                            <a href='/about' className='crumb'>
                                <span className='mx-1  crumb' href='/about'>About Us</span>
                            </a>
                            <span className='mx-1'>/</span>
                            <a href='/about/StudentsActivites' className='crumb'>
                                <span className='mx-1  ' >Students Activites</span>
                            </a>
                            
                            
                            <span className='mx-1'>/</span>
                            <span className='mx-1 crumbcolor' >Co-Curricular</span>
                        </p>
                        <h4>Co-Curricular</h4>
                        <div className='Pharm'>
                        <p >Students are trained to develop communication, creative thinking, problem-solving,
                            reasoning, critical thinking and collaborative abilities. Students are encourage to participate
                            journal clubs, College magazine editor, Poetry recitation, Story-writing, Debates and group
                            discussion, quiz programme, organizing exhibitions. Preparing charts, Model making.</p>
                            </div>
                            <Row>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/iv 1.png'className='img-fluid' alt='industrial visit'></img></Col>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/iv 2.png'className='img-fluid d-none d-md-block' alt='industrial visit'></img></Col>
                         </Row>
                    </Card>

                </Col>

            </Row>

        </Container>
    )
}
