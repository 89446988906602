import React from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { FaAngleRight } from "react-icons/fa";
import { Col, Container, Row } from "reactstrap";
import "../Homepage.css";

function Header() {
  const Color = (Colors) => {
    if (window.location.pathname === Colors) {
      return "#000";
    }
  };
  return (
    <div>
      <div
        className="navbar position-fixed top-0 d-none d-sm-none d-md-none d-lg-block"
        style={{ padding: "0" }}
      >
        <Container className="nav_bar_inner">
          <a href="/">
            <img src="/images/RVS.png" alt="logo1"  className="img-fluid"/>
          </a>
          <div className="nav_item text-end ">
            {/* About Program */}
            <div className="nav_link"
             style={{
              background:
                Color("/about") ||
                Color("/about/Testimonials") ||
                Color("/about/Infrastructure") ||
                Color("/about/Infrastructure/Academic") ||
                Color("/about/Infrastructure/OtherFacilities") ||
                Color("/about/Placements") ||
                Color("/about/StudentsActivites") ||
                Color("/about/StudentsActivites/StudentActivity") ||
                Color("/about/StudentsActivites/Curriculum") ||
                Color("/about/StudentsActivites/Co-Curricular") ||
                Color("/about/StudentsActivites/Extra-Curricular") ||
                Color("/about/StudentsActivites/Achivements") ||
                Color("/about/antiragging")||
                Color("/about/Contact")||
                Color("/about/EventsCalender"),
            }}
           
            >
              {" "}
              <a >
                About Us
                <AiOutlineCaretDown className="mx-2" size={10} />{" "}
              </a>
              <div className="mega_menu container p-5">
                <Row>
                  <h3> About Us</h3>
                </Row>
                <Row>
                 <a href="/about" className="text-decoration-none">
                 <h5>The Management</h5>
                 </a>
                </Row>
                <Row>
                <a href="/about/Testimonials" className="text-decoration-none">
                 <h5>Testimonials</h5>
                 </a>
                </Row>
                <Row>
                  <a href="/about/Infrastructure" className="text-decoration-none">
                  <h5>Infrastructure</h5>
                  </a>
                 
                  <Col lg={3}>
                    <ul className="list-unstyled">
                      <li>
                        <a
                          href="/about/Infrastructure/Academic"
                          className="text-decoration-none"
                        >
                          <FaAngleRight />
                          Academic
                        </a>
                      </li>
                     
                    </ul>
                  </Col>
                  <Col lg={3}>
                    <ul className="list-unstyled">
                      <li>
                        <a
                          href="/about/Infrastructure/OtherFacilities"
                          className="text-decoration-none"
                        >
                          <FaAngleRight />
                          Other Facilities
                        </a>
                      </li>
                     
                    </ul>
                  </Col>
                 
                </Row>
                <Row>
                <a href="/about/Placements" className="text-decoration-none">
                 <h5>Placements</h5>
                 </a>
                </Row>
                <Row>
                <a href="/about/StudentsActivites" className="text-decoration-none">
                 <h5>Students Activities</h5>
                 </a>
                  <Col lg={3}>
                    <ul className="list-unstyled">
                      <li>
                        <a
                          href="/about/StudentsActivites/Curriculum"
                          className="text-decoration-none"
                        >
                          <FaAngleRight />{" "}
                          Curriculum
                        </a>
                      </li>
                     
                    </ul>
                  </Col>
                  <Col lg={3}>
                    <ul className="list-unstyled">
                      <li>
                        <a
                          href="/about/StudentsActivites/Co-Curricular"
                          className="text-decoration-none"
                        >
                          <FaAngleRight />{" "}
                          Co-Curricular
                        </a>
                      </li>
                     
                    </ul>
                  </Col>
                  <Col lg={3}>
                    <ul className="list-unstyled">
                      <li>
                        <a
                          href="/about/StudentsActivites/Extra-Curricular"
                          className="text-decoration-none"
                        >
                          <FaAngleRight />{" "}
                          Extra-Curricular
                        </a>
                      </li>
                     
                    </ul>
                  </Col>
                  <Col lg={3}>
                    <ul className="list-unstyled">
                      <li>
                        <a
                          href="/about/StudentsActivites/Achivements"
                          className="text-decoration-none"
                        >
                          <FaAngleRight />{" "}
                          Achievements
                        </a>
                      </li>
                     
                    </ul>
                  </Col>
                 
                </Row>
                <Row>
                 <a href="/about/antiragging" className="text-decoration-none">
                 <h5>Anti Ragging</h5>
                 </a>
                </Row>
                <Row>
                 <a href="/about/Contact" className="text-decoration-none">
                 <h5>Contact us</h5>
                 </a>
                </Row>
                <Row>
                 <a href="/about/EventsCalender" className="text-decoration-none">
                 <h5>Event Calender</h5>
                 </a>
                </Row>
              </div>
            </div>
            {/* About Program */}

            {/* Programme Page */}

            <div className="nav_link"
            style={{
              background:
                Color("/programme") ||
                Color("/programme/B.pharm") ||
                Color("/programme/M.pharm") ||
                Color("/programme/M.pharm/Pharmaceutics") ||
                Color("/programme/M.pharm/PharmaceuticalAnalysis") ||
                Color("/programme/M.pharm/Pharmacology") ||
                Color("/programme/Pharm.D") ||
                Color("/programme/Ph.D") ||
                Color("/programme/CourseStructure"),
            }}
           
            >
              <a >
                Programmes
                <AiOutlineCaretDown className="mx-2" size={10} />
              </a>
              <div className="mega_menu container p-5">
                <Row>
                <a href="/programme/" className="text-decoration-none">
                  <h4>Programmes</h4>
                  </a>
                </Row>
                <Row>
                 <a href="/programme/B.pharm" className="text-decoration-none">
                 <h5>B.Pharm</h5>
                 </a>
                </Row>
                <Row>
                 <a href="/programme/M.pharm" className="text-decoration-none">
                 <h5>M.Pharm</h5>
                 </a>
                  <Col lg={4}>
                   <a href="/programme/M.pharm/Pharmaceutics" className="text-decoration-none">
                   <ul className="list-unstyled mega_menu_list">
                      <li>
                        {" "}
                        <FaAngleRight />Pharmaceutics
                      </li>
                    </ul>
                   </a>
                  </Col>
                  <Col lg={4}>
                    <a href="/programme/M.pharm/PharmaceuticalAnalysis" className="text-decoration-none">
                    <ul className="list-unstyled mega_menu_list">
                      <li>
                        {" "}
                        <FaAngleRight /> Pharmaceutical Analysis
                      </li>
                    </ul>
                    </a>
                  </Col>
                  <Col lg={4}>
                    < a href="/programme/M.pharm/Pharmacology" className="text-decoration-none">
                    <ul className="list-unstyled mega_menu_list">
                      <li>
                        {" "}
                        <FaAngleRight />  Pharmacology
                      </li>
                    </ul>
                    </a>
                  </Col>
                 
                </Row>
                <Row>
                 <a href="/programme/Pharm.D" className="text-decoration-none">
                 <h5>Pharm.D</h5>
                 </a>
                </Row>

                <Row>
                 <a href="/programme/Ph.D" className="text-decoration-none">
                 <h5>Ph.D</h5>
                 </a>
                </Row>
                <Row>
                 <a href="/programme/CourseStructure" className="text-decoration-none">
                 <h5>Course Structure</h5>
                 </a>
                </Row>
               
              </div>
            </div>
            {/* Programme*/}

            {/* Faculty */}
            <div className="nav_link"
             style={{
              background:
                Color("/faculty") ||
                Color("/faculty/FacultyDetails") ||
                Color("/faculty/VistingFaculty"),
            }}
            >
              {" "}
              <a >
                Faculty <AiOutlineCaretDown className="mx-2" size={10} />
              </a>
              <div className="mega_menu container p-4">
              <Row>
                 <h4>Faculty</h4>
                </Row>
                <Row>
                  <Col lg={4}>
                    < a href="/faculty" className="text-decoration-none">
                    <ul className="list-unstyled text-center mega_menu_list">
                      <li>
                        <FaAngleRight />
                        Faculty
                      </li>
                    </ul>
                    </a>
                  </Col>
                  <Col lg={4}>
                    < a href="/faculty/VisitingFaculty" className="text-decoration-none">
                    <ul className="list-unstyled text-center mega_menu_list">
                      <li>
                        <FaAngleRight />
                        Visiting Faculty
                      </li>
                    </ul>
                    </a>
                  </Col>
                 
                </Row>
              </div>
            </div>
            {/* Faculty */}

            {/* Research Activity*/}
            <div className="nav_link"
            style={{
              background:
                Color("/research/Publications") ||
                Color("/research/GrantsRecevied") ||
                Color("/research/AnimalHouse") ||
                Color("/research/MedicinalGarden") ||
                Color("/research/CommercialActivity"),
            }}
           
            >
              {" "}
              <a >
                Research Activities{" "}
                <AiOutlineCaretDown className="mx-2" size={10} />
              </a>
              <div className="mega_menu container p-5">
                <Row>
                  <h4>Research Activities</h4>
                </Row>
                <Row>
                  <a href='/research/Publications' className="text-decoration-none">
                  <h5>
                  <FaAngleRight /> Publications
                    </h5>
                  </a>
                </Row>
                <Row>
                 < a href="/research/GrantsRecevied" className="text-decoration-none">
                 <h5>
                  <FaAngleRight /> Grants Received
                    </h5>
                 </a>
                </Row>
                <Row>
                  < a href="/research/AnimalHouse" className="text-decoration-none">
                  <h5>
                  <FaAngleRight /> Animal House
                    </h5>
                  </a>
                </Row>
                <Row>
                 <a href="/research/MedicinalGarden"  className="text-decoration-none">
                 <h5>
                  <FaAngleRight /> Medicinal Garden
                    </h5>
                 </a>
                </Row>
                <Row>
                  <a href="/research/CommercialActivity" className="text-decoration-none">
                  <h5>
                  <FaAngleRight /> Commercial Activities
                    </h5>
                  </a>
                </Row>
               
              </div>
            </div>

            {/* Blog */}

            <p className="text-end fs-5 my-2">PREPARE FOR PHARMACY </p>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Header;
