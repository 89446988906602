import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ManagementPage from './Management/Managements';
import TestimonailsPage from './Testimonial/Testimonials';
import InfrastructurePage from './Infrastructure';
import Placements from './Placement/Placements';
import StudentActivitiesPage from './StudentsActivities/index';
import Contact from './contact/contact';
import Antiragging from './Antiragging/Antiragging';

import EventsCalenderPage from './EventsCalender/EventsCalender';
export default function AboutPage() {
     return (
         <Router>
              <Switch>
              <Route exact path={"/about"} component={ManagementPage} />
              <Route exact path={"/about/Testimonials"} component={TestimonailsPage} />
              <Route path={"/about/Infrastructure"} component={InfrastructurePage} />
              <Route exact path={"/about/Placements"} component={Placements} />
              <Route path={"/about/StudentsActivites"} component={StudentActivitiesPage} />
              <Route path={"/about/antiragging"} component={Antiragging} />
              <Route path={"/about/contact"} component={Contact} />
              <Route exact path={"/about/EventsCalender"} component={EventsCalenderPage} />
              </Switch>
         </Router>
     )
}
