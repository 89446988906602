import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faHome } from '@fortawesome/free-solid-svg-icons'
import { Card, Col, Row, Container } from 'react-bootstrap'
import Programmain from '../../SideMenu/ProgramMenu'

export default function Pharmaceutics() {
    return (
        <Container className='Aboutpage'>
            <Row >
                <Col lg={3}>
                    <Programmain />
                </Col>
                <Col lg={9}>
                    <Card className='p-4'>
                        <p>
                            <a href='/'>
                                <span className='mx-1 crumb'><FontAwesomeIcon icon={faHome} className='mx-1' /></span>
                            </a>
                            <span className='mx-1'>/</span>
                            <a href='/programme' className='crumb'>
                                <span className='mx-1  crumb' href='/about'>Programme</span>
                            </a>
                            <span className='mx-1'>/</span>
                            <a href='/programme/M.pharm' className='crumb'>
                                <span className='mx-1  crumb' href='/about'>M.Pharm</span>
                            </a>
                            <span className='mx-1'>/</span>
                            <span className='mx-1  crumbcolor' href=''>Pharmaceutics</span>
                        </p>
                        <h4>Pharmaceutics</h4>
                       <div className="Pharm">
                       <p>
                            The Department of Pharmaceutics is well equipped for both teaching and research
                            activities. It offers a Master and Doctoral degree program. Master program in Pharmaceutics was
                            started in the year of 2004 with an intake of 15 students. The major focus of the department’s
                            post graduate program is to provide students with a strong focus and training to prepare them for
                            careers in academia, Pharmaceutical industry or research institutions. We offer a training
                            environment which includes newer formulation laboratory instrumentation and technologies
                            monitored by our experienced and dedicated staff members. The department also has good
                            placement record.

                        </p>
                        <p>
                            We provide an opportunity in imparting knowledge in the fields of Novel Drug Delivery
                            Systems – NDDS and Herbal technology. A PG degree in pharmaceutics unlocks the plethora of
                            opportunities available in the health care industry, where they can involve in the sequence of
                            process from drug discovery, analytical characterization of the therapeutic moiety, designing of
                            dosage forms for the lead molecule using software involves in preclinical drug testing and
                            practicing of pharmacists in patient care, as pharmacists are the backbone of health care team and
                            they act as a bridge between the physician and the patient
                        </p>
                      
                       </div>
                       <Row>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/mp1.png'className='img-fluid' alt='b.form1'></img></Col>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/mp2.png'className='img-fluid d-none d-md-block' alt='b.form1'></img></Col>
                         </Row>
                        <h4>
                            Scope of the programs
                        </h4>
                        <p>
                            The programs furnish various opportunities to the students to engage in R&D projects
                            and to prosper their research skills. In Second Year M.Pharm students select a particular area of
                            research and perform a full-year project work in a specific site.
                        </p>

                        <h6 className=' fs-5'>Highlights</h6>
                        <div className="MPharm">
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"}/>
                                Training PG students to make them competent in the pharma world
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"}/>
                                Supervising Ph.D candidates with dedicated and well experienced staff members
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"}/>
                                The department library is enriched with abundant pharmaceutics books and academic
e- resources
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"}/>
                                Smart classrooms with AC seminar hall are present for ease of learning
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"}/>
                                Research activities on Novel drug delivery systems are studied
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"}/>
                                Sophisticated research laboratories are available with modern pharma equipments
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"}/>
                                PG students are taken for pharma industrial visits every year
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"}/>
                                Consultancy research work is also encouraged
                            </p>
                        </div>


                       
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
