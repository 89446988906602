import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MpharmOverview from './MpharmOverview';
import Pharmaceutics from './Pharmaceutics';
import PharmaceuticalAnalysis from './PharmaceuticalAnalysis';
import Pharmacology from './Pharmacology';
export default function MpharmPage() {
     return (
          <Router>
               <Switch>
                    <Route exact path={"/programme/M.Pharm"} component={MpharmOverview} />
                    <Route exact path={"/programme/M.pharm/Pharmaceutics"} component={Pharmaceutics} />
                    <Route exact path={"/programme/M.pharm/PharmaceuticalAnalysis"} component={PharmaceuticalAnalysis} />
                    <Route exact path={"/programme/M.pharm/Pharmacology"} component={Pharmacology} />
               </Switch>
          </Router>
     )
}
