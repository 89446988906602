import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row, Container } from "react-bootstrap";
import AboutMain from "../SideMenu/AboutMenu";
import pdf from "../../pdf/AntiRagging Commite.pdf"

export default function Antiragging() {
  return (
    <Container className="Aboutpage">
      <Row>
        <Col lg={3}>
          <AboutMain />
        </Col>
        <Col lg={9}>
          <Card className="p-4">
            <p>
              <a href="/">
                <span className="mx-1 crumb">
                  <FontAwesomeIcon icon={faHome} className="mx-1" />
                </span>
              </a>
              <span className="mx-1">/</span>
              <span className="mx-1  crumb" href="/about">
                About Us
              </span>
              <span className="mx-1">/</span>
              <span className="mx-1  crumbcolor" href="">
                Anti Ragging
              </span>
            </p>
            <h4>Anti Ragging</h4>
            <div className="Placement">
            <p >
              Anti-Ragging policy is strictly implemented in this institution.
              Based on the Guidelines of the UGC and the directions of the
              Hon’ble Supreme Court of India, all the steps and precautions to
              prevent ragging is effected in our institutions.
            </p>
            <p>
              Any student studying or seeking admission should not involve or
              indulge in any sort of ragging in the campus, hostels or
              elsewhere. Ragging in any manner is a criminal offense and
              strictly policed & prohibited. Students found guilty of involving
              in ragging will be subjected to severe punishment as offence under
              IPC leading to expulsion from the institute without any notice.
              The course of action for the students proven guilty in ragging
              will be as per the rules and regulations on prevention of ragging
              imposed from time to time by the Government / Apex Courts or any
              other competent authorities.
            </p>
            <a href={pdf}  target="_blank" rel="noreferrer" className='text-decoration-none p-4'>
                            <p  className='DownloadBrochureButton m-auto p-lg-1'>Anti ragging commitee</p>
                            </a>
            </div>
            
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
