import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import OverviewPage from './Overview/OverviewPage';

export default function ProgrammesPage() {
     return (
         <Router>
              <Switch>
              <Route path={"/programme"} component={OverviewPage}  />
              </Switch>
         </Router>
     )
}
