import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Main from './ProgrammeMain';
import BpharmPage from '../Specialization/Bpharm';
import MpharmPage from '../Specialization/M.Pharm/Mpharm';
import PharmD from '../Specialization/PharmD';
import PhD from '../Specialization/PhD';
import CourseStructure from '../CourseStructure/CourseStructure';
export default function OverviewPage() {
    return (
        <Router>
            <Switch>
                <Route exact path={"/programme"} component={Main} />
                <Route exact path={"/programme/B.Pharm"} component={BpharmPage} />
                <Route path={"/programme/M.Pharm"} component={MpharmPage} />
                <Route exact path={"/programme/Pharm.D"} component={PharmD} />
                <Route exact path={"/programme/Ph.D"} component={PhD} />
                <Route path={"/programme/CourseStructure"} component={CourseStructure} />
            </Switch>
        </Router>
    )
}
