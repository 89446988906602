import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faHome } from '@fortawesome/free-solid-svg-icons'
import ResearchMain from '../SideMenu/SideMenu'

export default function CommercialActivity() {
    return (
        <Container className= " Aboutpage ">
            <Row>
                <Col lg={3}>
                    <ResearchMain />
                </Col>
                <Col lg={9}>
                    <Card className='p-4'>
                        <p>
                            <a href="/">
                                <span className="mx-1 crumb">
                                    <FontAwesomeIcon icon={faHome} className="mx-1" />
                                </span>
                            </a>
                            <span className="mx-1">/</span>
                            <a href="/research/Publications" className="crumb">
                                <span className="mx-1  crumb" href="">
                                    Research Activity
                                </span>
                            </a>
                            <span className="mx-1">/</span>
                            <span className="mx-1  crumbcolor" href="">
                                Commercial Activities
                            </span>
                        </p>
                        <h4>Commercial Activities</h4>
                        <span>
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                className="mx-3"
                                color="#A60D1F"
                            />
                            Spectroscopy analysis -IR sample, UV sample
                        </span>
                        <span>
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                className="mx-3"
                                color="#A60D1F"
                            />
                            Chromatography analysis – HPLC
                        </span>
                        <span>
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                className="mx-3"
                                color="#A60D1F"
                            />
                            Pharmacological studies.
                        </span>
                        <span>
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                className="mx-3"
                                color="#A60D1F"
                            />
                            Drug testing laboratory.
                        </span>
                        <span>
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                className="mx-3"
                                color="#A60D1F"
                            />
                            Drug stability studies.
                        </span>
                        <span>
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                className="mx-3"
                                color="#A60D1F"
                            />
                            Food analysis - Beverages / Edible oils.
                        </span>
                        <span>
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                className="mx-3"
                                color="#A60D1F"
                            />
                            Cosmetic Analysis.
                        </span>
                        <span>
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                className="mx-3"
                                color="#A60D1F"
                            />
                            Herbal drugs Standardizations.
                        </span>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
