import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Publications from './Publication/Publications';
import GrantsRecevied from './GrantsRecevied/GrantsRecevied';
import ResearchProject from './ResearchProject/ResearchProject';
import AnimalHouse from './AnimalHouse/AnimalHouse';
import MedicinalGarden from './MedicalGarden/MedicinalGarden';
import CommercialActivity from './CommericalActivity/CommercialActivity';

export default function ResearchActivityPage() {
     return (
          <Router>
               <Switch>
                    <Route exact path={"/research/Publications"} component={Publications} />
                    <Route exact path={"/research/GrantsRecevied"} component={GrantsRecevied} />
                    <Route exact path={"/research/ResearchProject"} component={ResearchProject} />
                    <Route exact path={"/research/AnimalHouse"} component={AnimalHouse} />
                    <Route exact path={"/research/MedicinalGarden"} component={MedicinalGarden} />
                    <Route exact path={"/research/CommercialActivity"} component={CommercialActivity} />
               </Switch>
          </Router>
     )
}
