import React from 'react'
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";


export default function SideMenu() {
    const Color=(color)=>{
      if(window.location.pathname===color){
        const style={
          textDecoration:"underline",
          color:"#7f1010"
        }
        return style
      }
    }

  return (
    <Card className="aboutmenu bg-light bg-opacity-100 d-none d-sm-block  d-lg-block d-md-none">
      <ul className="list-unstyled submenu text-start">
        <a href="/research/Publications" className="text-decoration-none">
          <li className="border p-2 " style={Color("/research/Publications")}>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1" />
            Publications
          </li>
        </a>
        <a href="/research/GrantsRecevied" className="text-decoration-none">
          <li className="border p-2 " style={Color("/research/GrantsRecevied")}>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1" />
            Grants Received
          </li>
        </a>
        {/* <a href="/research/ResearchProject" className="text-decoration-none">
          <li className="border p-2 " style={{color:Color("/research/ResearchProject")}}>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1" />
            Research Project
          </li>
        </a> */}
        <a href="/research/AnimalHouse" className="text-decoration-none">
          <li className="border p-2 " style={Color("/research/AnimalHouse")}>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1" />
            Animal House
          </li>
        </a>
        <a href="/research/MedicinalGarden" className="text-decoration-none">
          <li className="border p-2 " style={Color("/research/MedicinalGarden")}>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1" />
            Medicinal Garden
          </li>
        </a>
        <a href="/research/CommercialActivity" className="text-decoration-none">
          <li className="border p-2 " style={Color("/research/CommercialActivity")}>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1" />
            Commercial Activities
          </li>
        </a>
      </ul>
    </Card>
  )
}
