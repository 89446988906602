import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Row, Col, Form, Modal } from 'react-bootstrap'
import "./Form.css"

export default function Enquries(props) {
  return (
    <div>
      <Modal
        {...props}

        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='brochure1'
        show={props.show}
        
      >

        <Modal.Body closeButton >
        <FontAwesomeIcon icon={faTimes} onClick={props.onHide} className="BrochureIcon float-end"/>
          <h4 className='text-center my-2'>
            Enquiry Now
          </h4>
          <Row>

            <Col lg={12} md={12} className='text-center my-3 '>
              <Form className=' mx-5 my-2'>
                <Form.Group className="mb-3" >
                  <Form.Control type="Name" placeholder="Name" />
                </Form.Group>

                <Form.Group className="mb-3" >
                  <Form.Control type="Email" placeholder="Email" />
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Control type="Name" placeholder="Mobile Number" />
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Control type="Name" placeholder="Message" />
                </Form.Group>
              <a href='/'> <p className=' brochurebutton '>
                  Submit 
                </p></a>
               
              </Form>
            </Col>
          </Row>
        </Modal.Body>

      </Modal>
    </div>
  )
}
