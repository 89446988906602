import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faHome } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row, Container } from "react-bootstrap";
import AboutMain from '../SideMenu/AboutMenu';


export default function EventsCalender() {
    return (
        <Container className="Aboutpage">
            <Row>
                <Col lg={3}>
                    <AboutMain/>
                </Col>
                <Col lg={9}>
            <Card className='p-4'>
                <p>
                    <a href='/'>
                    <span className='mx-1 crumb'><FontAwesomeIcon icon={faHome}  className='mx-1'/></span>
                    </a>
                <span className='mx-1'>/</span>
                <span className='mx-1  crumb' href='/about'>About Us</span>
                <span className='mx-1'>/</span>
                <span className='mx-1  crumbcolor' href=''>EventsCalender</span></p> 
                <h4>Events Calender</h4>
                <h4 className='text-center py-4 text-danger'>Under Construction</h4>

              
            </Card>
            </Col>
            </Row>
            </Container>
    )
}


