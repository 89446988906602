import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row, Container, Table } from "react-bootstrap";
import AboutMain from "../SideMenu/AboutMenu";

export default function Academic() {
  return (
    <Container className="Aboutpage">
      <Row>
        <Col lg={3} md={9}>
          <AboutMain />
        </Col>
        <Col lg={9} md={12}>
          <Card className="p-4">
            <p>
              <a href="/" className="crumb">
                <span className="mx-1 crumb">
                  <FontAwesomeIcon icon={faHome} className="mx-1 " />
                </span>
              </a>
              <span className="mx-1">/</span>
              <a href="/about" className="crumb">
                <span className="mx-1  crumb" href="/about">
                  About Us
                </span>
              </a>
              <span className="mx-1">/</span>
              <span className="mx-1  crumbcolor">Academic</span>
            </p>
            <h4>Academic</h4>
            <h5>Lab</h5>
            <div className="Academic">

            <p>
              The state of art facilities of the laboratories huge industry
              standard equipment and specialist teaching laboratories. We allow
              students to work in small groups.
            </p>
            <h6>Pharmaceutical chemistry/ Analysis laboratory</h6>
            <Row>
              <Col lg={6} md={6}>
                <img src="/images/pharmaceutical-chemistry-1.png" className="img-fluid" alt="Pharmaceutical Chemistry"/>
              </Col>
              <Col lg={6} md={6}>
                <img src="/images/pharmaceutical-chemistry-2.png" className="img-fluid d-none d-md-block " alt="Pharmaceutical Chemistry"/>
              </Col>
            </Row>
            <p className="ParaStyle my-4">
              In Pharmaceutical Chemistry / Analysis teaching laboratory,
              students learn skills and techniques in the context of chemistry
              activities include , synthesis of a drug , physicochemical studies
              of a drug and quality control methods of drugs and chemicals using
              sophisticated equipment’s such as IR, UV and HPLC etc.
            </p>
            <h6>Pharmaceutics laboratory</h6>
            <Row>
              <Col lg={6} md={6}>
                <img src="/images/pharmaceutics-1.png" className="img-fluid d-none d-md-block" alt="Pharmaceuticus"/>
              </Col>
              <Col lg={6} md={6}>
                <img src="/images/pharmaceutics-2.png" className="img-fluid" alt="Pharmaceuticus"/>
              </Col>
            </Row>
            <p className="ParaStyle my-4">
              In Pharmaceutics, the students prepare emulsions, capsules,
              tablets and other formulation of pharmaceutical dosage forms. Also
              carry out quality control tests using instruments similar to those
              used by Pharmaceutical industry, for example dissolution tester
              allows student to investigate drug release from tablets.
            </p>
            <h6>Pharmacology laboratory</h6>
            <Row>
              <Col lg={6} md={6}>
                <img src="/images/pharmacology-1.png" className="img-fluid d-none d-md-block" alt="pharmacology"/>
              </Col>
              <Col lg={6} md={6}>
                <img src="/images/pharmacology-2.png" className="img-fluid" alt="pharmacology"/>
              </Col>
            </Row>
            <p className="ParaStyle my-4">
              Physiology and Pharmacology lab mainly used for the study of
              development in a clinical setting, which contains models and
              posters to help students learn about body systems. The students
              are encouraged to investigate the effect of different type’s
              medication on cells and tissues, as well as some general body
              organ physiology.
            </p>
            <h6>Pharmacognosy laboratory</h6>
            <Row>
              <Col lg={6} md={6}>
                <img src="/images/pharmacognosy-1.png" className="img-fluid" alt="pharmacognosy"/>
              </Col>
              <Col lg={6} md={6}>
                <img src="/images/pharmacognosy-2.png" className="img-fluid d-md-block d-none" alt="pharmacognosy"/>
              </Col>
            </Row>
            <p className="ParaStyle my-4">
              The Pharmacognosy lab enlighters knowledge in the field of
              medicinal and natural products. The students will be trained to do
              the phytochemical analysis of the crude drugs and study the
              microscopical characters of any herbal drugs having definite
              cellular structures.
            </p>
            <h6>Central instrumentation room</h6>
            <p className="ParaStyle my-4">
              <p>
                The institution is provided with central instrumentation room
                where quality control of Pharmaceutical drugs are carries using
                carried using instruments of electronic facilities such as UV
                spectrophotometer, HPLC, FTIR etc and solvent extractors.
              </p>
              <p>
                The institution is provided with machine room to facilitate the
                students to prepare dosage forms which need heavy machines to
                use.
              </p>
            </p>
            <h6>Pharmacy Practice</h6>
            <Row>
              <Col lg={6} md={6}>
                <img src="/images/practice-1.png" className="img-fluid d-none d-md-block" alt="practice"/>
              </Col>
              <Col lg={6} md={6}>
                <img src="/images/practice-2.png" className="img-fluid" alt="practice"/>
              </Col>
            </Row>
            <p className="ParaStyle my-4">
              <p>
                In Pharmacy Practice is provided with computerized simulated
                patient and get to experience dispensing mock prescriptions
                using real medication and the pharmacy dispensing system used in
                pharmacies around the globe.
              </p>
              <p>
                The institution is provided with English language /
                communication lab to train the students, help the students to
                move around the world with hassle free movement for work / job
                or higher education purpose. Our teaching rooms and laboratories
                in the campus develop in to a major higher education institution
                with state of the art facilities.
              </p>
            </p>
            <h6>
              Library
            </h6>

            <Row>
               <Col lg={6} md={6}>
                 <img src="/images/library-1.png" className="img-fluid" alt="library" />
               </Col>
               <Col lg={6} md={6}>
                 <img src="/images/library-2.png" className="img-fluid d-none d-md-block" alt="library" />
               </Col>
            </Row>
            <p className="ParaStyle my-4">
              <p>
                The library is an integral part of our college. The library
                has a crucial role in supporting education as well as research
                activities. The institution has exclusive library facility for
                students and teaching, constructed in the ground floor with
                total build up area of 1920 sq ft., called intellectual area
                of the campus.
              </p>
              <p>
                The library is located in a clean quiet atmosphere in the
                campus, managed by library advisory committee consisting of
                Principal, HOD’s of all departments, one qualified librarian
                with MLISC and two attenders.
              </p>
              <p>
                There are separate sections available in the library such as
                Administrator and book issue section , reading section,
                reference section, journal section and e – library.
              </p>
              <p>
                All the books are segregated subject wise and shelved properly
                labeled cupboards and are given unique accession number and
                barcoded.
              </p>
              <p>
                New arrivals, latest volumes and issues of the subscribed
                journals are displayed in the journal section. The library is
                supported with internet facility and digital entry is
                maintained.
              </p>
              <p>
                The library is supported and filled with collections include
                Pharmaceutics, Pharmaceutical Chemistry, Pharmacology,
                Pharmacognosy, Pharmacy management and related subjects. Total
                400 readers are enlisted in the library register and an
                average of 90 students use the library every day. 3 books are
                issued to PG students and 2 books to UG students for a period
                of one week.
              </p>
            </p>
            <h6>
              Layout of the Library
            </h6>
            <p>
              <p>
                The library management system software gives the complete information about the
                library and follows the guidelines given in ISO certification of our institution.
              </p>
              <p>
                Subject and speciality wise books and volumes for Pharmacy programme
              </p>
            </p>
            <Table bordered size="sm" >
              <thead className="text-center"  >
                <tr >

                  <th>SPECIALITY</th>
                  <th>TITLES</th>
                  <th>VOLUMES</th>
                </tr>
              </thead>
              <tbody>
                <tr>

                  <td>Pharmaceutical Chemistry</td>
                  <td>565</td>
                  <td>1488</td>
                </tr>
                <tr>

                  <td>Pharmaceutical Analysis</td>
                  <td>149</td>
                  <td>348</td>
                </tr>
                <tr>
                  <td>Pharmacology</td>
                  <td>289</td>
                  <td>776</td>
                </tr>
                <tr>
                  <td>Pharmacognosy</td>
                  <td>188</td>
                  <td>553</td>
                </tr>
                <tr>
                  <td>Pharmaceutical Biotechnology</td>
                  <td>216</td>
                  <td>467</td>
                </tr>
                <tr>
                  <td>Pharmacy Practice</td>
                  <td>129</td>
                  <td>540</td>
                </tr>
                <tr>
                  <td>Pharmacy management</td>
                  <td>146</td>
                  <td>260</td>
                </tr>
                <tr>
                  <td>Others</td>
                  <td>1407</td>
                  <td>3846</td>
                </tr>
              </tbody>
            </Table>
            <h6>HOSPITAL</h6>
            <p>
              Our college is having MOU with KG Hospital and post graduate research Institute. It is a
              multispecialty hospital with 500 bed strength which place a major support to train the Pharm.D
              students for their Clinical study. The Clinical training is given to students in the various
              specialities. like General medicine, Pulmonology, Nephrology, Urology, Paediatrics, ENT,
              Ophthalmology, Obstetrics and Gynaecology, ICU, Orthopaedics, Psychiatrics, Surgery,
              Cardiology , Diabetology, Pharmacy , etc., Our students providing the Ward round
              participation, Intervention, Patient Counselling, Prescription analysis, ADR Monitoring and
              reporting and Providing Drug Information to all patients and health care professionals.
            </p>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
