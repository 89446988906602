import React, {useState} from "react";
import { faBars, faPhone, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "react-transition-group";
import SidebarMenu from "./SidebarMenu";



export default function SilderMenu() {
  const [NavOpen, setNavOpen] = useState(false);

  const openNav = () => {
    setNavOpen(true);
  };
  const closeNav = () => {
    setNavOpen(false);
  };

  return (
    <div className="Sidebar">
      <nav className="SidebarNav">
        <div className="SidebarIcon p-2" onClick={openNav}>
          <FontAwesomeIcon icon={faBars} />
          </div>
          <Transition in={NavOpen} timeout={300} mountOnEnter unmountOnExit>
            {value => {
              return (
                <div>
                  <SidebarMenu Click={value} />
                  <div
                    className="Overlay"
                    style={
                      value === "entering"
                        ? { animation: "Show .3s forwards" }
                        : value === "entered"
                        ? { opacity: "1" }
                        : { animation: "Show .3s reverse forwards" }
                    }
                    onClick={closeNav}
                  ></div>
                  <div
                    className="CloseButton"
                    style={
                      value === "entering"
                        ? { animation: "Show .3s forwards" }
                        : value === "entered"
                        ? { opacity: "1" }
                        : { animation: "Show .3s reverse forwards" }
                    }
                    onClick={closeNav}
                  >
                    <FontAwesomeIcon icon={faTimes} color="white" />
                  </div>
                </div>
              );
            }}
          </Transition>
        
        <div className="Sidebarlogo">
             <a href="/"  className="logoFont">
          <img
            src="/images/logo.png"
            alt="SidebarLogo"
            className="logo2 "
          />
          </a>
        
        </div>
        <div className="SidebarIcon2 p-2">
          <a herf="tel:+918778079024"><FontAwesomeIcon icon={faPhone} /></a>
        </div>
        <br className="clear" />
      </nav>
    </div>
  );
}
