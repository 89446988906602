import React from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import DownloadBrochure from "../EnquriesForm/DownloadBrochure";

export default function Card1() {
  const [modalShow, setModalShow] = React.useState(false);
  
  const ProgrammeCard = [
    {
      Name: "B.Pharm",
      img: "/programmeImg/B.Pharm.png",
      link:"/programme/B.pharm"
    }, {
      Name: "Pharm.D",
      img: "/programmeImg/Pharm.D.png",
      link:"/programme/Pharm.D"
    },
    {
      Name: "Ph.D",
      img: "/programmeImg/Ph.D.png",
      link:"/programme/Ph.D"
    },
    {
      Name: "Pharmaceutics",
      img: "/programmeImg/Pharmaceutical-Analysis.png",
      link:"/programme/M.pharm/Pharmaceutics"
    },
    {
      Name: " Pharmaceutical Analysis",
      img: "/programmeImg/Pharmaceutics.png",
      link:"/programme/M.pharm/PharmaceuticalAnalysis"
    },
    {
      Name: "Pharmacology",
      img: "/images/button/Rat.png",
      link:"/programme/M.pharm/Pharmacology"
    },
   
  ];

  const ProgrammeView=ProgrammeCard.map(value=>
  
      <Col lg={4} md={6}>
      <a href={value.link} className="crumb">
              <Card style={{ width: "18rem" }} className="my-3 mx-auto">
                <Card.Img
                  variant="top"
                  src={value.img}
                />
                <Card.Body className="cardBody">
                  <Card.Title className="course">{value.Name}</Card.Title>
                </Card.Body>
              </Card>
            </a>
      </Col>
    
  )
  return (
    <Container>
      <Row>
        <Row className="my-3">
          <h3 className="HeadingStyle">Programmes</h3>
          <p className="ParaStyle">
            The RVSCOPS - Pharmacy Course offers specializations in B.Pharm,
            M.Pharm – 3 specializations, Pharm.D, Ph.D that leads to degree with
            strong pharma foundations and technological fundamentals to stay
            ahead of the competition.
          </p>
        </Row>
      <Row>
      {ProgrammeView}
      </Row>
        <h6 className="text-center my-5">
          To know more about the Specializations Courses in Pharmacy RVS
          <p
            type="button"
            onClick={() => setModalShow(true)}
            className="DownloadBrochureButton"
          >
            Download Brochure
          </p>
        </h6>

        <DownloadBrochure show={modalShow} onHide={() => setModalShow(false)} />
      </Row>
    </Container>
  );
}
