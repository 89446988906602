import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row, Container } from "react-bootstrap";


export default function JobOpportunities() {
    return (
        <Container className="Aboutpage">
        <Row>

            <Col lg={10} className='mx-auto'>
                <Card className='p-4'>
                    <p>
                        <a href='/'>
                            <span className='mx-1 crumb'><FontAwesomeIcon icon={faHome} className='mx-1' /></span>
                        </a>
                        <span className='mx-1'>/</span>
                        <span className='mx-1  crumbcolor' href=''>Job Opportunities</span></p>
                    <h4>Job Opportunities</h4>
                    <div className='Pharm'>
                    <p>As a Pharma graduate, you can take up job roles like-drug inspector in drug control
                        department, drug analyst in Government other then drug manufacturing companies in
                        manufacturing and quality control division. But now demand increasing for new age job roles
                        like-big data analyst, computational pharmacist, health care management scientist, life cycle
                        management scientist etc.</p>
                    <p>
                        When look in to the job profile of Pharm.D course, he/she may become a consultant
                        pharmacist, clinical pharmacist, senior clinical pharmacist, Medicare safety manager and
                        Pharmacovigilance area etc.
                    </p>
                    </div>
                </Card>
            </Col>
        </Row>
    </Container>
    )
}
