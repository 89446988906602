import React from 'react'
import { Modal, Row, Col, Form } from 'react-bootstrap'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./Form.css"

export default function DownloadBrochure(props) {

  return (
    <div>
      <Modal
        {...props}

        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='brochure'
      >

        <Modal.Body closeButton >
        <FontAwesomeIcon icon={faTimes} onClick={props.onHide} className="BrochureIcon float-end"/>
          <h4 className='text-center my-2'>Register and Download Brochure </h4>
          <Row>
            <Col lg={5} md={5} className='text-center my-3 d-none d-md-block'>
              <img src='/images/web/brochure.png' className='img-fluid mx-3' alt='brochuremodal' />
            </Col>
            <Col lg={6} md={6} className='text-center my-3'>
              <Form className='mx-5 my-2'>
                <Form.Group className="mb-3" >
                  <Form.Control type="Name" placeholder="Name" />
                </Form.Group>

                <Form.Group className="mb-3" >
                  <Form.Control type="Email" placeholder="Email" />
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Control type="Name" placeholder="Mobile Number" />
                </Form.Group>
           <a  href='/'>  <p className='brochurebutton'>
                  Download
                </p></a>
               
              </Form>
            </Col>
          </Row>
        </Modal.Body>

      </Modal>
    </div>
  )
}
