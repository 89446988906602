import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faHome} from '@fortawesome/free-solid-svg-icons'
import AboutMain from '../../SideMenu/AboutMenu'

export default function ExtraCurricular() {
    return (
        <Container className="Aboutpage">
            <Row>
                <Col lg={3}>
                    <AboutMain/>
                </Col>
                <Col lg={9}>
               <Card className="p-4">
               <p >
                <a  href='/' className='crumb'><span className='mx-1 crumb'><FontAwesomeIcon icon={faHome}  className='mx-1 '/></span></a>
                <span className='mx-1'>/</span>
                <a href='/about' className='crumb'>
                <span className='mx-1  crumb' href='/about'>About Us</span>
                </a>
                <span className='mx-1'>/</span>
                <a href='/about/StudentsActivites' className='crumb'>
                <span className='mx-1  ' >Students Activites</span>
                </a>
               
                <span className='mx-1'>/</span>
                <span className='mx-1 crumbcolor' >Extra-Curricular</span>
                </p>
                <h4>ExtraCurricular</h4>
                <div className='Pharm'>
                <p>
                    Students are encouraged to take active participation in sports and cultural programmes.
College has an excellent field for football, Tennis, Basketball, Volley Ball, cricket etc., The
Depart of Physical education encourages and train the students of the institute to participate in
various sports and athletic events at inter college and university level sports meet. Students have
participated in the District, state level sports & cultural meet & won prizes in the past & continue
doing it. The institute has a well - equipped gymnasium with modern equipment for both the
staff members and students.
                            </p>
                            </div>
                            <Row>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/cul1.png'className='img-fluid' alt='function'></img></Col>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/cul2.png'className='img-fluid d-none d-md-block' alt='function'></img></Col>
                         </Row>
               </Card>
                </Col>
            </Row>
        </Container>
    )
}
