import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import AboutMain from "../../SideMenu/AboutMenu";

export default function Curriculum() {
    return (
        <Container className="Aboutpage">
            <Row>
                <Col lg={3}>
                    <AboutMain />
                </Col>
                <Col lg={9}>
                    <Card className="p-4">
                        <p>
                            <a href="/">
                                <span className="mx-1 crumb">
                                    <FontAwesomeIcon icon={faHome} className="mx-1 " />
                                </span>
                            </a>
                            <span className="mx-1">/</span>
                            <a href="/about" className="crumb">
                                <span className="mx-1  crumb">About Us</span>
                            </a>
                            <span className="mx-1">/</span>
                            <a href="/about/StudentsActivites" className="crumb">
                                <span className="mx-1  crumb">Students Activities</span>
                            </a>
                            
                            <span className="mx-1">/</span>
                            <span className="mx-1  crumbcolor">Curriculum</span>
                        </p>
                        <h4>Curriculum</h4>
                        <div className='Pharm'>
                        <p>
                        In all the Pharmacy progrmmes conducted, the teaching is by lectures, seminars,
workshops and practical laboratory classes, which take up approximately two thirds of the
programme. Placements form a key part of the teaching programme.
                        </p>
                        <p>
                        A variety of assessment methods are used including practical dispensing / preparation of
medicines, examinations, objective structured clinical examinations (for Pharm. D programme),
presentations(individual and group)written reports ( assignments), internal assessment and end of
the year / semester written examinations.
                        </p>
                        <p>
                        The course of study for B.Pharm and M.Pharm, shall include semester wise theory and
practical as given by the approval and affiliating bodies and the number of hours to be devoted to
each theory, tutorial and practical course in any semester.
                        </p>
                        <p>
                        For a student studying the B.Pharm course, each academic year of the programme will
comprise 260 learning hours per semester and 140 hours per semester for M. Pharm programme
173 hours per week for Pharm. D programme upto 5 th year and internship 01 year which include
both direct theory, practical and other modules of learnings.
                        </p>
                        <p>
                        Based on the performance, each student shall be awarded a final letter grade at the end of
semester of each course (B.Pharm and M.Pharm only). After the end of the course of study and
passing the final semester CGPA is calculated which reflects on final report card / transcript.
                        </p>
                        <p>
                        In case of Pharm.D course upto 5 th year of study, only annual pattern of study is followed
and marks score card carry the marks scored on individual subject and class of pass is defined.
For, internship programme point scoring based on performance based reflects on certificate
issued.
                        </p>
                        </div>
                        
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
