import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import StudentsActivites from './StudentsSubmenu/StudentsActivitesMain';

import Curriculum from './StudentsActivity/Curriculum';
import CoCurricular from './StudentsActivity/CoCurricular';
import ExtraCurricular from './StudentsActivity/ExtraCurricular';
import Achivements from './Achivement/Achivements';

export default function StudentActivitiesPage() {
     return (
          <Router>
               <Switch>
                    <Route exact path={"/about/StudentsActivites"} component={StudentsActivites} />
                  
                    <Route exact path={"/about/StudentsActivites/Curriculum"} component={Curriculum} />
                    <Route exact path={"/about/StudentsActivites/Co-Curricular"} component={CoCurricular} />
                    <Route exact path={"/about/StudentsActivites/Extra-Curricular"} component={ExtraCurricular} />
                    <Route exact path={"/about/StudentsActivites/Achivements"} component={Achivements} />
               </Switch>
          </Router>
     )
}
