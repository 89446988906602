import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router'
import { Card, Col, Container, Row } from 'react-bootstrap'
import FacultyMain from '../SideMenu/FacultySideMenu'
import FacultyDetails from '../FacultyDetail.json'
import { styles } from '../../styles'

export default function ViewDetails() {
    const faculties = FacultyDetails
    const [faculty, setFaculty] = useState([])
    const location = useLocation()

    useEffect(() => {

        const getFaculty = faculties.filter((value) => { return value.id === location.state.detail })
        setFaculty(getFaculty)
    },[])

    const publications = (value) => {
        if (value === '') {
            return (false)
        } else {
            return (
                <div>
                    <h4>Publications</h4>
                    <p>{value}</p>
                </div>)
        }
    }

    const Presentations=(value)=>{
        if(value === ""){
            return(false)
        }else{
            return(
                <div>
                <h4>Presentations</h4>
                <p>{value}</p>
            </div>
            )
        }
    }
    const Conference =(value)=>{
        if(value===""){
            return(false)
        }else{
            return(
                <div>
                    <h4>Conference</h4>
                    <p>{value}</p>
                </div>
            )
        }
    }
    const Professional =(value)=>{
        if(value===""){
            return(false)
        }else{
            return(
                <div>
                    <h4>Professional</h4>
                    <p>{value}</p>
                </div>
            )
        }
    }
    const Academic =(value)=>{
        if(value===""){
            return(false)
        }else{
            return(
                <div>
                    <h4>Academic</h4>
                    <p>{value}</p>
                </div>
            )
        }
    }
    const Interest =(value)=>{
        if(value===""){
            return(false)
        }else{
            return(
                <div>
                    <h4>Interest</h4>
                    <p>{value}</p>
                </div>
            )
        }
    }
    
    const Others = (value) => {
        if (value === "") {
            return (false)
        } else {
            return (
                <div>
                    <h4>Others</h4>
                    <p>{value}</p>
                </div>
            )
        }
    }
   

    // const renderPublications=
    const RenderFaculty = faculty.map((value) =>
        <Row className="mt-3 mt-md-3">
            <Col lg={3}>
                <FacultyMain />
            </Col>
            <Col lg={6} md={8}>
                <Card className='p-4'>
                    <p>
                        <a href='/'><span className='mx-1 crumb'><FontAwesomeIcon icon={faHome} className='mx-1' /></span></a>
                        <span className='mx-1'>/</span>
                        <a href='/faculty' className='crumb'>
                            <span className='mx-1  crumb' >Faculty</span>
                        </a>
                        <span className='mx-1'>/</span>
                        <span className='mx-1  '>FacultyDetail</span>
                        <span className='mx-1'>/</span>
                        <span className='mx-1  crumbcolor'>{value.Name}</span>

                    </p>
                    <h3 className="my-3">{value.Name}</h3>
                    {publications(value.Publications)}
                    {Presentations(value.Presentations)}
                    {Conference(value.Conference)}
                    {Professional(value.Professional)}
                    {Academic(value.Academic)}
                    {Others(value.Others)}
                    {Interest(value.Interest)}
                    <h5 className="FacultyContact">Contact:<span className="mx-4 fw-normal">{value.Contact}</span></h5>
                    <h5 className="Facultymail">
                        E-Mail:<span className="Facultymail mx-3  d-sm-inline d-md-inline">{value.Email}</span>
                    </h5>

                </Card>
            </Col>
            <Col lg={3} md={4}>
                <Card className='facultycard p-3'>
                    <Card className='p-4' style={styles.facultycard}>
                        <img className='facultyimg img-fluid mx-auto' src={value.img} alt='' />
                        <br />
                        <h5>Specialization</h5>
                        <p>{value.Department}</p>
                        <h5>Qualification</h5>
                        <p>{value.Degree}</p>
                        <h5>Area of Interest</h5>
                        <p>{value.Interest}</p>

                    </Card>
                </Card>
            </Col>
        </Row>

    )



    return (

        <Container className='top'>
            {RenderFaculty}
        </Container>
    )
}
