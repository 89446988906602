import React from 'react'
import { Card, Container, Row, Col, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faHome } from '@fortawesome/free-solid-svg-icons'
import ResearchMain from '../SideMenu/SideMenu'
import pdf from '../../pdf/CPCSEA APPROVAL.pdf'

export default function AnimalHouse() {
    return (
        <Container className=" Aboutpage ">
            <Row>
                <Col lg={3}>
                    <ResearchMain />
                </Col>
                <Col lg={9}>
                    <Card className='p-4'>
                        <p>
                            <a href="/">
                                <span className="mx-1 crumb">
                                    <FontAwesomeIcon icon={faHome} className="mx-1" />
                                </span>
                            </a>
                            <span className="mx-1">/</span>
                            <a href="/research/Publications" className="crumb">
                                <span className="mx-1  crumb" href="">
                                    Research Activity
                                </span>
                            </a>
                            <span className="mx-1">/</span>
                            <span className="mx-1  crumbcolor" href="">
                                Animal House
                            </span>
                        </p>
                        
                        <h4>Animal House</h4>
                        
                        <h6>Registration Number :1012/PO/Re/S/06/CPCSEA</h6>
                        {/* <Table bordered responsive="sm">
                            <tbody>
                                <tr>
                                    <th>Coordinator animal facility</th>
                                    <td>Dr.D.Benito Johnson</td>
                                </tr>
                                <tr>
                                    <th>Veterinary officer</th>
                                    <td>Dr.T.Elango</td>
                                </tr>
                                <tr>
                                    <th>Technician</th>
                                    <td>Mr.S.P.Sakthivel</td>
                                </tr>
                                <tr>
                                    <th>Attender</th>
                                    <td>Ms.A.Nivetha</td>
                                </tr>
                                <tr>
                                    <th>Animal Attender</th>
                                    <td>Mrs.K.Ranjitha</td>
                                </tr>
                                <tr>
                                    <th>Sweepers</th>
                                    <td>Mrs.J.Dhanam & Mrs.A.Ratha</td>
                                </tr>

                            </tbody>
                        </Table> */}
                        <h5  >Number of animal maintained</h5>
                        <Table bordered responsive="sm">
                            <tbody>
                                <tr>
                                    <th>Mice</th>
                                    <td>25 Numbers</td>
                                </tr>
                                <tr>
                                    <th>Wister Rat</th>
                                    <td>35 Numbers</td>
                                </tr>
                                <tr>
                                    <th>New Zealand White Rabbit</th>
                                    <td>04 Numbers</td>
                                </tr>
                            </tbody>
                        </Table>
                        <h5 >Facilities for experiment work</h5>
                        <span>
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                className="mx-3"
                                color="#7f1010"
                            />
                            A well-furnished animal laboratory
                        </span>
                        <span>
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                className="mx-3"
                                color="#7f1010"
                            />
                            Ladies dress changing rooms
                        </span>
                        <span>
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                className="mx-3"
                                color="#7f1010"
                            />
                            Scrub room facility for male
                        </span>
                        <span>
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                className="mx-3"
                                color="#7f1010"
                            />
                            Cleaned working area with well-trained lab facility
                        </span>
                    

                        <h5 className=' my-3'>Laboratory</h5>
                        <div className='Publications'>
                        <p>
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                className="mx-3"
                                color="#7f1010"
                            />
                            A well-equipped lab containing with floor for doing animal experiment which has Working table, weighing machine, a water bath
                        </p>
                        <p>
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                className="mx-3"
                                color="#7f1010"
                            />
                            An incubator, micro oven, refrigerator, centrifuge, digital balance, biochemical Analyzer , surgical instrument ( various setup for doing different experiments) and important equipment are also available
                        </p>
                     
                        <h5 className=' my-3'>Washing and sterilizing area</h5>
                        <p >This area has without disturbance of water supply, sink for washing the polypropylene
                            cages and water bottle etc., autoclave, hot air oven are available for sterilizing the surgical
                            instrument and cages for frequent intervals.</p>

                        <h5 className=' my-3'>Feed</h5>
                        <p>We purchase feed pellets from commercial vendor, (M/s sai enterprises), composition of
                            various types of pellets are available. The animals were provided with the pellets and fresh
                            sprouted grains two time’s day. Potable water (analyzed for microbe’s contamination at
                            frequent intervals) is available for the animal   ad libitum</p>

                        <h5 className=' my-3'>Research facility</h5>
                        <p>The CPCSEA and IAEC approve the student/ faculty to perform research when it
                            involves the animal. These can be performed in the animal house.</p>

                            <a href={pdf}  target="_blank" rel="noreferrer"  className='text-decoration-none'>
                            <p  className='DownloadBrochureButton m-auto p-lg-1'>CPCSEA APPROVAL</p>
                            </a>
                           

</div>

                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
