import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Card, Col, Row,  Container } from 'react-bootstrap'
import Programmain from '../SideMenu/ProgramMenu'
import Enquries from "../../HomePage/EnquriesForm/Enquries";

export default function Bpharm() {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <Container className='Aboutpage'>
            <Row >
                <Col lg={3}>
                    <Programmain />
                </Col>
                <Col lg={9}>
                    <Card className='p-4'>
                        <p>
                            <a href='/'>
                                <span className='mx-1 crumb'><FontAwesomeIcon icon={faHome} className='mx-1' /></span>
                            </a>
                            <span className='mx-1'>/</span>
                            <a href='/programme' className='crumb'>
                                <span className='mx-1  crumb' >Programme</span>
                            </a>
                            <span className='mx-1'>/</span>
                            <span className='mx-1  crumbcolor' href=''>B.Pharm</span></p>

                        <p>
                            
                        </p>
                        <h4>B.Pharm</h4>
                        <p className="Pharm">
                            As per the present curriculum in India, a B.Pharm degree mainly prepares the candidates
                            to work in the pharmaceutical industry. The course structure of the degree includes practical
                            training in the pharmaceutical industry environment. After completion of B.Pharm degree, one
                            may become manufacturing chemist (or) analytical chemist for which minimum qualification
                            prescribed in drugs and cosmetic act is B.Pharm graduation.</p>
                         <Row>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/b.form1.png'className='img-fluid' alt='b.form1'></img></Col>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/b.form3.png'className='img-fluid d-none d-md-block' alt='b.form1'></img></Col>
                         </Row>
                         <br></br>

                       <br></br>
                        <p onClick={() => setModalShow(true)} className='DownloadBrochureButton m-auto p-lg-1'>Talk to the Expert</p>
                    </Card>
                </Col>
            </Row>
            <Enquries show={modalShow} onHide={() => setModalShow(false)} />
        </Container>
    )
}
