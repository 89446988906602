import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Programmain from "../../SideMenu/ProgramMenu";
import Enquries from "../../../HomePage/EnquriesForm/Enquries";

export default function Mpharm() {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <Container className='Aboutpage'>
            <Row>
                <Col lg={3}>
                    <Programmain />
                </Col>
                <Col lg={9}>
                    <Card className="p-4">
                        <p>
                            <a href="/">
                                <span className="mx-1 crumb">
                                    <FontAwesomeIcon icon={faHome} className="mx-1" />
                                </span>
                            </a>
                            <span className="mx-1">/</span>
                            <a href="/programme" className="crumb">
                                <span className="mx-1  crumb">Programme</span>
                            </a>
                            <span className="mx-1">/</span>
                            <span className="mx-1  crumbcolor" href="/program">
                                M.Phram
                            </span>
                        </p>
                        <h4>M.Phram</h4>
                        <p>
                            The complexity of medical formulae and critical problems in making
                            drugs, M.Pharm graduates have advantage in R&D. This programme is
                            designed with specialization in area wise manufacturing of drugs
                            and it bringing a finished product ready to market.
                        </p>
                        <Row className="my-5">
                            <Col lg={6} md={6}>
                                <h6>Pharmaceutics</h6>
                                <p>
                                    Delve deep into various formulation research and development.
                                </p>
                                <Link
                                    to={"/programme/M.pharm/Pharmaceutics"}
                                    className="text-decoration-none"
                                >
                                    <img
                                        className="img img-fluid"
                                        src="/images/button/M1.png"
                                        alt="Pharmaceutics"
                                    />
                                </Link>
                            </Col>
                            <Col lg={6} md={6}>
                                <h6>Pharmaceutical Analysis</h6>
                                <p className="justify-content-center">
                                    Enhance your ability in analytical, method development and
                                    validation techniques for synthetic and natural products as
                                    per industry requirements.
                                </p>
                                <Link
                                    to={"/programme/M.pharm/PharmaceuticalAnalysis"}
                                    className="text-decoration-none"
                                >
                                    <img
                                        className="img img-fluid"
                                        src="/images/button/M2.png"
                                        alt="Pharmaceutical Analysis"
                                    />
                                </Link>
                            </Col>
                        </Row>
                        <Row className="my-5 justify-content-center">
                            <Col lg={6} md={6}>
                                <h6>Pharmacology</h6>
                                <p>
                                    Learn more about novel chemical substances and analyzing the
                                    effects of established medicinal compounds.
                                </p>
                                <Link
                                    to={"/programme/M.pharm/Pharmacology"}
                                    className="text-decoration-none"
                                >
                                    <img
                                        className="img img-fluid"
                                        src="/images/button/M3.png"
                                        alt="Pharmacology"
                                    />
                                </Link>
                            </Col>
                        </Row>
                        
                        <p onClick={() => setModalShow(true)} className='DownloadBrochureButton m-auto p-lg-1 '>Talk to the Expert</p>
                    </Card>
                </Col>
            </Row>
            <Enquries show={modalShow} onHide={() => setModalShow(false)} />
        </Container>
    );
}
