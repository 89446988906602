import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import ResearchMain from '../SideMenu/SideMenu'

export default function MedicinalGarden() {
    return (
        <Container className=" Aboutpage ">
            <Row>
                <Col lg={3}>
                    <ResearchMain />
                </Col>
                <Col lg={9}>
                    <Card className='p-4'>
                        <p>
                            <a href="/">
                                <span className="mx-1 crumb">
                                    <FontAwesomeIcon icon={faHome} className="mx-1" />
                                </span>
                            </a>
                            <span className="mx-1">/</span>
                            <a href="/research/Publications" className="crumb">
                                <span className="mx-1  crumb" href="">
                                    Research Activity
                                </span>
                            </a>
                            <span className="mx-1">/</span>
                            <span className="mx-1  crumbcolor" href="">
                                Medicinal Garden
                            </span>
                        </p>
                        <h4>Medicinal Garden</h4>
                        <div className='Publications'>
                        <p>Medicinal garden designed and maintained by department of Pharmacognosy. Medicinal
                            garden plays a vital role in the cultivation and collection of medicinal plants. It is very helpful for
                            new drug discovery through the extraction and isolation of different varieties of
                            phytoconstituents. Different plant family herbals, species are cultured here.</p>
                        <p>For UG, PG students - The research on medicinal plants, environmental research will be
                            very beneficial.</p>
                            <Row>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/md1.png'className='img-fluid' alt='b.form1'></img></Col>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/md2.png'className='img-fluid d-none d-md-block' alt='b.form1'></img></Col>
                         </Row>
                         </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
