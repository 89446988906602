import React from 'react'
import { BrowserRouter as Router, Switch} from 'react-router-dom'
import Screens from './components/Screens';

function App() {

  return (
    <Router>
      <Switch>
        <Screens/>
      </Switch>
    </Router>
    
  );
}

export default App;
