import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faHome } from '@fortawesome/free-solid-svg-icons'
import ResearchMain from '../SideMenu/SideMenu'

export default function Publications() {
    const Details=[
        {
            para:"Arulraj P Akelesh T, Vijaypradeep and Venkatanarayanan et.,al. Antibacterial Properties of different parts of calotropis Gigantea: An In-vivo study, wjpls, 2021 , Vol.7, Issue 5,78-52."
        },
        {
            para:"Barish, M. Mumtaj Begum, E. Abraham Theodore and R. Venkatanarayanan.Formulation and evaluation of nigella sativa transdermal patches for anti-cancer activity,2021, Volume 10, Issue 11, 1635-1647."
        },
        {
            para:"R.Sivasakthi, J.Subadradevi, N.Dhandapani, R.Venkatanarayanan, P.Nithyan. Evaluating the reduction of hypertension by using some biomarker at a tertiary care hospital.J.Hosp.Pharmacy, 2021,16(3), July- Sep, 13."
        },
        {
            para:"S.S.Rajendran, N.Santhi, A.Jenifer, Akshaya karthigeyan, S.Dharan raj, Preparation and evaluation of herbal dentifrice,Asian Journal of Pharmaceutical Analysis and Medicinal Chemistry, 8(1), 2020, 16-23."
        },
        {
            para:"Barish, E. Abraham Theodore, M. Mumtaj Begum, S. Princy, S. Dibu Babu. Formulation and evaluation of Loperamide liquisolid compacts, International Journal of Research in Pharmaceutical and Nano Sciences, 8(5), 2019, 247-253."
        },
        {
            para:"N.Santhi, S.S.Rajendran , et.al.. Assessment of Antimicrobial Potential Of Chopped Onion, WJPMR, 2019,5(10), 107-109."
        },
        {
            para:"D. Benito Johnson, U.Mohammed Aneez, S.Iswarya, R. Venkatanarayan. Anti-microbial activity of aqeous alcoholic extract containing Commiphora wighti and lagenena siceria.Research J. Pharm. and Tech. 2018; 2(4)."
        },
        {
            para:" Arulraj P., Babu P., Bavatharini A., Manikandan V., and Venkatanarayanan R. A Review on antimicrobial and Wound Healing activity of Tridax Procumbens. ejbps, 2018,Volume 5, Issue 11, 461-465."
        },
        {
            para:"T.Akelesh, et.al.. Formulation and comparative evaluation of sustained release tablets of Levofloxacin using different polymers, World Journal of Pharmaceutical Research, 2018,7(5), 755-762."
        },
        {
            para:"T.Akelesh, et.al.. Formulation and evaluation of sustained release matrix tablets of atenolol hydrochloride, World Journal of Pharmaceutical and Medical Research, 2018,4(12), 297-306."
        },
        {
            para:"Rajendran SS, Venkatanarayanan R, Santhi N et.al.. Amelioration of 1, 2 Dimethylhydrazine Induced Tumor Promotion Response by Novel Benzimidazole Derivative Nanoparticle in Wistar Rats. J Young Pharm. 2018;10(3):292-8."
        },
        {
            para:"Barish, M. Mumtaj Begum, E. Abraham Theodore and R. Venkatanarayanan.Formulation and evaluation of nigella sativa transdermal patches for anti-cancer activity,2021, Volume 10, Issue 11, 1635-1647."
        },
        {
            para:"Arulraj P., Bavatharini A., Manikandan V., Venkatanarayanan R. et.al. A Typical review on antimicrobial activity of acalypha indica. World journal of pharmaceutical and medical research. 2017 (3) 11, 61-65."
        }, 
        {
            para:"D. Benito Johnson, Akhil Raj, R. Venkatanarayan. Antidiabetic Activity and Toxicity Studies of Ethanolic Extract of Polialthia longifolia Roots. Research J. Pharm. and Tech.2017; 10(5): 1305-1312."
        }
        
    ]

    const PublicationsDetails=Details.map(value=>
        <div className='Publications'>
            <p>
            <FontAwesomeIcon
                icon={faAngleDoubleRight}
                className="mx-3"
                color="#A60D1F"
            />
                {value.para}
                </p>
        </div>
    )
    return (
        <Container className=" Aboutpage ">
            <Row>
                <Col lg={3}>
                    <ResearchMain />
                </Col>
                <Col lg={9}>
                    <Card className="p-4">
                        <p>
                            <a href="/">
                                <span className="mx-1 crumb">
                                    <FontAwesomeIcon icon={faHome} className="mx-1" />
                                </span>
                            </a>
                            <span className="mx-1">/</span>
                            <a href="/research/Publications" className="crumb">
                                <span className="mx-1  crumb">
                                    Research Activity
                                </span>
                            </a>
                            <span className="mx-1">/</span>
                            <span className="mx-1  crumbcolor">
                                Publications
                            </span>
                        </p>
                        <h4>Publications</h4>
                       {PublicationsDetails}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
