import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import OverviewFaculty from './OverviewFaculty-Details/OverviewFaculty';
import FacultyDetails from './FacultyDetails/ViewDetails'
import VistingFaculty from './VisitingFaculty/VistingFaculty';
import VistingDetails from './VisitingFaculty/VistingDetails';
export default function FacultyPage() {
     return (
          <Router>
               <Switch>
                    <Route exact path={"/faculty"} component={OverviewFaculty} />
                    <Route exact path={"/faculty/FacultyDetails"} component={FacultyDetails} />
                    <Route exact path={"/faculty/VisitingFaculty"} component={VistingFaculty}/>
                    <Route exact path={"/faculty/VisitingFaculty/Visiting"} component={VistingDetails}/>
                    

               </Switch>
          </Router>
     )
}
