import React from 'react'
import { Card, Row,Col, Container } from 'react-bootstrap'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHome} from '@fortawesome/free-solid-svg-icons'
import AboutMain from '../SideMenu/AboutMenu'


export default function OtherFacilities() {
  return (
    <Container className="Aboutpage">
            <Row>
              <Col lg={3}>
                <AboutMain/>
              </Col>
              <Col lg={9}>
                <Card className="p-4">
                  <p>
                    <a href='/'>
                    <span className="mx-1 crumb">
                      <FontAwesomeIcon icon={faHome} className="mx-1" />
                    </span>
                    </a>
                    <span className="mx-1">/</span>
                    <a href="/about" className='crumb'>
                    <span className="mx-1  crumb" >
                      About Us
                    </span>
                    </a>
                    <span className="mx-1">/</span>
                    <a href='/about/Infrastructure' className='crumb'>
                    <span className="mx-1 crumb ">
                      Infrastructure
                    </span>
                    </a>
                    <span className="mx-1">/</span>
                    <span className="mx-1  crumbcolor" href="">
                     Other Facilities
                    </span>
                  </p>
                  <h2 className='text-center my-lg-3 my-md-3 my-3'>Transport</h2>
                  <Row>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/tp1.png'className='img-fluid' alt='Transport'></img></Col>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/tp2.png'className='img-fluid my-lg-0 my-md-0 my-3 ' alt='Transport'></img></Col>
                         </Row>
                  <h2 className='text-center my-lg-3 my-md-3 my-3'> Cafeteria</h2>
                  <Row>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/canteen1.png'className='img-fluid' alt=' Cafeteria'></img></Col>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/canteen2.png'className='img-fluid my-lg-0 my-md-0 my-3' alt=' Cafeteria'></img></Col>
                         </Row>
                  <h2  className=' text-center my-lg-3 my-md-3 my-3'>Girls Hostel</h2>
                  <Row>
                     <Col lg={6} md={6}> 
                             <img src='/images/web/gh1.png'className='img-fluid' alt='Girls hostal'></img></Col>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/gh2.png'className='img-fluid my-lg-0 my-md-0 my-3' alt='Girls hostal'></img></Col>
                         </Row>
                         <h2  className='text-center my-lg-3 my-md-3 my-3'>Boys Hostel</h2>
                  <Row>
                     <Col lg={6} md={6}> 
                             <img src='/images/web/bh.png'className='img-fluid' alt='Boys hostal'></img></Col>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/bh1.png'className='img-fluid my-lg-0 my-md-0 my-3' alt='Boys hostal'></img></Col>
                         </Row>
                </Card>
              </Col>
            </Row>
          </Container>
  )
}


