import React from "react";
import { Card } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

function AboutMenu() {
  const Color = (color) => {
    if (window.location.pathname === color) {
      const style={
        textDecoration:"underline",
        color:"#7f1010"
      }
      return style
    }
  }

  const location = useLocation(); // To get the current route
  const Infrastructure = () => {

    //If condition to check whether the path name is /about/specializations
    if (
      location.pathname === "/about/Infrastructure" ||
      location.pathname === "/about/Infrastructure/Academic" ||
      location.pathname === "/about/Infrastructure/OtherFacilities"
    ) {
      return (
        <Card>
          {" "}
          <ul className=" list-unstyled p-4">
            <a
              href="/about/Infrastructure/Academic"
              className="text-decoration-none"
            >
              <li style={ Color("/about/Infrastructure/Academic")}>
                <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                Academic
              </li>
            </a>
            <a
              href="/about/Infrastructure/OtherFacilities"
              className="text-decoration-none"
            >
              <li style={Color("/about/Infrastructure/OtherFacilities")}>
                <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                Other Facilities
              </li>
            </a>
          </ul>
        </Card>
      );
    }
  };

  const StudentsActivities = () => {
   
    //If condition to check whether the path name is /about/specializations
    if (
      location.pathname === "/about/StudentsActivites" ||
      
      location.pathname === "/about/StudentsActivites/Curriculum" ||
      location.pathname === "/about/StudentsActivites/Co-Curricular" ||
      location.pathname === "/about/StudentsActivites/Extra-Curricular" ||
      location.pathname === "/about/StudentsActivites/Achivements"
    ) {
      return (
        <Card>
          {" "}
          <ul className=" list-unstyled p-4">
          <a
              href="/about/StudentsActivites/Curriculum"
              className="text-decoration-none"
            >
              <li style={Color("/about/StudentsActivites/Curriculum")}>
                <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                Curriculum
              </li>
            </a>
            <a
              href="/about/StudentsActivites/Co-Curricular"
              className="text-decoration-none"
            >
              <li style={Color("/about/StudentsActivites/Co-Curricular")}>
                <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                Co-Curricular
              </li>
            </a>
            <a
              href="/about/StudentsActivites/Extra-Curricular"
              className="text-decoration-none"
            >
              <li style={Color("/about/StudentsActivites/Extra-Curricular")}>
                <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                Extra-Curricular
              </li>
            </a>
      
            <a
              href="/about/StudentsActivites/Achivements"
              className="text-decoration-none"
            >
              <li style={Color("/about/StudentsActivites/Achivements")}>
                <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                Achievements & Awards
              </li>
            </a>

          </ul>
        </Card>
      );
    }
  };
 

  const infrastructure = Infrastructure(); // card variable holds the html elements to render the content under Specialization sub menu
  const studentsActivities = StudentsActivities(); // card variable holds the html elements to render the content under Specialization sub menu



  return (
    <Card className="AboutMain d-none d-sm-block  d-lg-block d-md-none">
      <ul className="list-unstyled submenu text-start">
        <a href="/about" >
          <li className="border p-2 " style={Color('/about')}>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1" />
            The Management
          </li>
        </a>
        <a href={"/about/Testimonials"} className="text-decoration-none">
          {" "}
          <li className="border my-1 p-2" style={Color('/about/Testimonials')}>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1" />
            Testimonials
          </li>
        </a>

        <a href="/about/Infrastructure">
          <li className=" border my-1 p-2 " style={
            
              Color('/about/Infrastructure')  ||
              Color("/about/Infrastructure/Academic") ||
              Color("/about/Infrastructure/OtherFacilities")
             }>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1 " />
            <span>Infrastructure</span>
          </li>
        </a>
        {/*  This section shows the value of card variable  */}
        {infrastructure}
        <a href="/about/Placements">
          <li className="border my-1 p-2" style={Color('/about/Placements')}>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1" />
            Placements
          </li>
        </a>
        <a href="/about/StudentsActivites">
          <li className=" border my-1 p-2 " style={
             
             Color('/about/StudentsActivites')||
             Color("/about/StudentsActivites/StudentActivity")||
             Color('/about/StudentsActivites/Curriculum') ||
             Color("/about/StudentsActivites/Co-Curricular") ||
             Color("/about/StudentsActivites/Extra-Curricular")||
             Color("/about/StudentsActivites/Achivements") 
             
             }>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1 " />
            <span>Students Activities</span>
          </li>
        </a>
        {/*  This section shows the value of card variable  */}
        {studentsActivities}
        <a href={"/about/antiragging"}>
          <li className="border my-1 p-2" style={Color('/about/antiragging')}>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1" />
            Anti Ragging
          </li>
        </a>
        <a href={"/about/Contact"}>
          <li className="border my-1 p-2" style={Color('/about/Contact')}>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1" />
            Contact Us
          </li>
        </a>


        <a href={"/about/EventsCalender"}>
          <li className="border my-1 p-2" style={Color('/about/EventsCalender')}>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1" />
            Events Calender
          </li>
        </a>

      </ul>
    </Card>
  );
}

export default AboutMenu;
