import React from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

export default function FacultySideMenu() {
  const Color = (color) => {
    if (window.location.pathname === color) {
      const style={
        textDecoration:"underline",
        color:"#7f1010"
      }
      return style
    }
  };
  return (
    <Card className="aboutmenu bg-light bg-opacity-100 d-none d-sm-block  d-lg-block d-md-none">
      <ul className="list-unstyled submenu text-start">
        <a href="/faculty" className="text-decoration-none">
          <li
            className="border p-2 "
            style={Color("/faculty") || Color("/faculty/FacultyDetails")}
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1" />
            Faculty
          </li>
        </a>
        <a href="/faculty/VisitingFaculty" className="text-decoration-none">
          <li
            className="border p-2 "
            style={Color("/faculty/VisitingFaculty")}
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-1" />
            Visiting Faculty
          </li>
        </a>
      </ul>
    </Card>
  );
}
