import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import AboutMain from "../../SideMenu/AboutMenu";
import Enquries from "../../../HomePage/EnquriesForm/Enquries";

export default function StudentsActivites() {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <Container className="Aboutpage">
            <Row>
                <Col lg={3}>
                    <AboutMain />
                </Col>
                <Col lg={9}>
                    <Card className="p-4">
                        <p>
                            <a href="/">
                                <span className="mx-1 crumb">
                                    <FontAwesomeIcon icon={faHome} className="mx-1" />
                                </span>
                            </a>
                            <span className="mx-1">/</span>
                            <a href="/about" className="crumb">
                                <span className="mx-1  crumb" href="/about">
                                    About Us
                                </span>
                            </a>
                            <span className="mx-1">/</span>
                            <span className="mx-1  crumbcolor" href="/">
                                Students Activities
                            </span>
                        </p>
                        <h4>Students Activities</h4>
                        <p>
                        Students activities exist to complement the academic curriculum and to augment the student&#39;s
educational experience.
                        </p>
                        <Row className="StudentsActivites">
                        <Col lg={6} md={6}>
                                <h6>Curriculum</h6>
                                <p>Student experiences occur in educational process, combines innovative course work with
research experience, provides our students with scientific background.</p>
                                <Link to={'/about/StudentsActivites/Curriculum'} className='text-decoration-none'>

                                    <img className='img img-fluid' src='/images/button/Curriculum.png' alt='Curriculam' />


                                </Link>

                            </Col>
                            <Col lg={6} md={6} >
                                <h6>Co-Curricular</h6>
                                <p className='justify-content-center'>An interactive session with hands on training under the guidance of expert, seminar focus on
specific topic and guest lectures enriching the students with latest updates of the industries.</p>
                                <Link to={'/about/StudentsActivites/Co-Curricular'} className='text-decoration-none'>
                                    <img className='img img-fluid' src='/images/button/co-curriculur.png' alt='co-curricular' />
                                </Link>

                            </Col>
                            </Row>
                            <Row className="my-5">
                            <Col lg={6} md={6} >
                                <h6>Extra-Curricular</h6>
                                <p>Extra Talent management in cultural, sports and literary activities.</p>
                                <Link to={'/about/StudentsActivites/Extra-Curricular'} className='text-decoration-none'>
                                    <img className='img img-fluid' src='/images/button/extra.png' alt='Extra-curricular' />
                                </Link>
                            </Col>
                            <Col lg={6} md={6}>
                                <h6>Achievements & Awards</h6>
                                <p className="justify-content-center">
                                Accolades for the distinction students.
                                </p>
                                <Link
                                    to={"/about/StudentsActivites/Achivements"}
                                    className="text-decoration-none"
                                >
                                    <img
                                        className="img img-fluid"
                                        src="/images/button/Achievement.png"
                                        alt="/"
                                    />
                                </Link>
                            </Col>
                        </Row>

                        <p
                            onClick={() => setModalShow(true)}
                            className="brochurebutton m-auto my-3 mx-auto m-md-auto mt-md-5"
                        >
                            Talk to the Expert
                        </p>
                    </Card>
                </Col>
            </Row>
            <Enquries show={modalShow} onHide={() => setModalShow(false)} />
        </Container>
    );
}
