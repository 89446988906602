import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome ,faAngleRight} from '@fortawesome/free-solid-svg-icons'
import { Card, Col, Row, Container } from 'react-bootstrap'
import Programmain from '../../SideMenu/ProgramMenu'


export default function PharmaceuticalAnalysis() {
    return (
        <Container className='Aboutpage'>
            <Row >
                <Col lg={3}>
                    <Programmain />
                </Col>
                <Col lg={9}>
                    <Card className='p-4'>
                        <p>
                            <a href='/'>
                                <span className='mx-1 crumb'><FontAwesomeIcon icon={faHome} className='mx-1' /></span>
                            </a>
                            <span className='mx-1'>/</span>
                            <a href='/programme' className='crumb' >
                                <span className='mx-1  crumb' >Programme</span>
                            </a>
                            <span className='mx-1'>/</span>
                            <a href='/programme/M.pharm' className='crumb'>
                                <span className='mx-1  crumb' >M.Pharm</span>
                            </a>
                            <span className='mx-1'>/</span>
                            <span className='mx-1  crumbcolor' href=''>Pharmaceutical Analysis</span></p>
                        <h4>Pharmaceutical Analysis</h4>
                       <div className="Pharm">
                       <p>
                            The Department of Pharmaceutical Analysis was established in the year 2004. The
                            Department of Pharmaceutical Analysis has advanced facilities for carrying out qualitative and
                            quantitative analysis of synthetic drugs, pharmaceuticals dosage forms, natural products using
                            sophisticated analytical instruments such as HPLC, UV, IR , Flame Photometer,
                            Nepheloturbidometry, Fluorimeter, Flame photometer,.</p>
                        <p>
                            A modern and advance instruments facilities are accessible in the department to provide
                            knowledge and skills to understand about calibration, validation and how it can be applied to
                            industry and thus to improve the quality of the products. The department actively involved
                            in various aspects of Impurity and pesticides analysis, Impurity profiling and characterization of
                            degradants, Stability testing of phytopharmaceutical and their protocol preparation. The
                            department designed to impart knowledge on analysis of food constituents and finished food
                            products.
                        </p>
                        <Row>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/mpa1.png'className='img-fluid' alt='b.form1'></img></Col>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/mpa2.png'className='img-fluid d-none d-md-block' alt='b.form1'></img></Col>
                         </Row>
                        <p>
                            The Department has conducted several Workshop and hands on training programme for
                            analytical pharmacists. Research projects also performing in collaboration with Ayurveda
                            College Coimbatore. Journal club provides the support and facilities to developing the research
                            skills of faculty and students.
                        </p>

                       </div>
                        <h6 className=' fs-5'>Highlights</h6>
                        <div className="MPharm">
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"} />
                                Quality control & Quality assurance.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"} />
                                Impurity and pesticide analysis
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"} />
                                Herbal drug standardization and validation.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"} />
                                Food Analysis Techniques.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"} />
                                Bio-analytics Techniques.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"} />
                                Method development and validation for drug analysis.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"} />
                                Outcome- based learning.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faAngleRight} className="mx-3" color={"#a60d1e"} />
                                Skill-based lab techniques.
                            </p>
                        </div>

                      
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
