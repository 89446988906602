import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faHome } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row, Container } from "react-bootstrap";
import AboutMain from '../SideMenu/AboutMenu';


export default function Contact() {
    return (
        <Container className="Aboutpage">
            <Row>
                <Col lg={3}>
                    <AboutMain/>
                </Col>
                <Col lg={9}>
            <Card className='p-4'>
                <p>
                    <a href='/'>
                    <span className='mx-1 crumb'><FontAwesomeIcon icon={faHome}  className='mx-1'/></span>
                    </a>
                <span className='mx-1'>/</span>
                <span className='mx-1  crumb' href='/about'>About Us</span>
                <span className='mx-1'>/</span>
                <span className='mx-1  crumbcolor' href=''>contact</span></p> 
                
                <h4>Contact Us</h4>

<h6>Address</h6>
<span>RVS College of Pharmaceutical Sciences</span>
<span>242-B, Trichy Road, Sulur - 641402</span>
<span>Coimbatore, TamilNadu, India</span>

<hr/>
<h6>For Guidance/Admissions</h6>
<Row>
    <Col lg={6}>
    <span>Dr R.Venkatanarayanan</span><br/>
<span>Phone:  <a href="tel:8012133444" style={{color:"#a60d1f"}}>+91 8012133444 </a></span><br/>
<span>Email: <span className="crumbcolor"> <a href='mailto:venkatanarayanan@rvsgroup.com' style={{color:"#a60d1f"}}>venkatanarayanan@rvsgroup.com</a> </span></span>
    </Col>
    <Col lg={6}>
    <span>Mr. Cecil williams</span><br/>
<span>Phone:  <a href='tel:9715374000' style={{color:"#a60d1f"}}>+91 9715374000</a></span><br/>
<span>Email:<span className='crumbcolor'> <a href='mailto:joby@rvsgroup.com ' style={{color:"#a60d1f"}}>joby@rvsgroup.com</a></span></span>
    </Col>
</Row>
<hr/>
<h6>For Other Queries</h6>
<p>Office: 0422 2687 421 / 603</p>


</Card>
   </Col>
</Row>

              
            
            </Container>
    )
}


