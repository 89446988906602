import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight,  faHome } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row, Container } from "react-bootstrap";
import Programmain from "../SideMenu/ProgramMenu";
import Enquries from "../../HomePage/EnquriesForm/Enquries";

export default function PharmD() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <Container className='Aboutpage'>
      <Row>
        <Col lg={3}>
          <Programmain />
        </Col>
        <Col lg={9}>
          <Card className="p-4">
            <p>
              <a href="/">
                <span className="mx-1 crumb">
                  <FontAwesomeIcon icon={faHome} className="mx-1" />
                </span>
              </a>
              <span className="mx-1">/</span>
              <a href="/programme" className="crumb">
                <span className="mx-1  crumb" href="/about">
                  Programme
                </span>
              </a>
              <span className="mx-1">/</span>
              <span className="mx-1  crumbcolor" href="">
                Pharm.D
              </span>
            </p>
            <h4>Pharm.D</h4>
           <div className="Pharm">
           <p>
              The graduates through their education and training, have an
              in-depth knowledge of pharmacology and therapeutics, adverse drug
              reactions and drug interactions and qualifies them alone to make
              professional judgements relating to medicines. They assess,
              monitor, initiate and modify patients medications to assure that
              drug therapy regimens are safe and effective. They are trained
              manpower for disease management, tracking ADR, patient counseling,
              drug monitoring out come and Pharmacoeconomics for effective and
              cost effective care. They provide trustworthy drug information to
              both patient and physician.
            </p>
            <p>
              The department of Pharmacy Practice import the Clinical training
              to students in the area of hospital set up. The clinical and
              hospital pharmacy training also provided to our students. Our
              college is having MOU with KG Hospital and post graduate research
              Institute. It is a multispeciality hospital with 500 bed strength
              which place a major support to train the Pharm.D students for
              their Clinical study. The Clinical training is given to students
              in the various specialities like General medicine, Pulmonology,
              Nephrology, Urology, Paediatrics, ENT, Ophthalmology, Obstetrics
              and Gynaecology, ICU, Orthopaedics, Psychiatry, Surgery,
              Cardiology , Diabetology, Pharmacy , etc., Our students involving in
              the Ward round participation, Intervention, Patient Counseling,
              Prescription analysis, ADR Monitoring and reporting and Providing
              Drug Information to all patients and health care professionals.
              Our department have a well-equipped department library with
              various National and International journals and approved access to
              the Micromedex 2.0 software as ready reckoner for any drug related
              search.
            </p>
            <Row>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/fd1.png'className='img-fluid' alt='b.form1'></img></Col>
                             <Col lg={6} md={6}> 
                             <img src='/images/web/fd2.png'className='img-fluid d-none d-md-block' alt='b.form1'></img></Col>
                         </Row>
           </div>
            <h6 className="m-auto m-md-0 my-2 my-md-2 fs-5">Key Features</h6>
            <div className="PharmD">
              <p>
                <FontAwesomeIcon icon={faAngleRight} color={"#a60d1e"} className="mx-3" />
                Practical/Clinical Training of undergraduates to make them competent in academics,research and Clinical activities.
              </p>
              <p>
                <FontAwesomeIcon icon={faAngleRight} color={"#a60d1e"} className="mx-3" />
                Guiding the students for self-promotion in seminars and assignments.
              </p>
              <p>
                <FontAwesomeIcon icon={faAngleRight} color={"#a60d1e"} className="mx-3" />
                Train the students for Case Based Learning in Hospital set up.
              </p>
              <p>
                <FontAwesomeIcon icon={faAngleRight} color={"#a60d1e"} className="mx-3" />
                Well sophisticated Hospital Training by various specialities by experienced doctors.
              </p>
              <p>
                <FontAwesomeIcon icon={faAngleRight} color={"#a60d1e"} className="mx-3" />
                E-learning process is well equipped with digital library like e-books, Internet connection and Copier facilities.
              </p>
             
              <p>
                <FontAwesomeIcon icon={faAngleRight} color={"#a60d1e"} className="mx-3" />
                Journal Club Presentation is performed by Internship students in smart class to
                modern teaching and learning process.
              </p>
              <p>
                <FontAwesomeIcon icon={faAngleRight} color={"#a60d1e"} className="mx-3" />
                Research activities and projects on Clinical case are carried out as per Institutional
                Human Ethical Committee -guidelines
              </p>
              <p>
                <FontAwesomeIcon icon={faAngleRight} color={"#a60d1e"} className="mx-3" />
                National level seminar and CMEs conducted every year for fill the education gap in
                the department.
              </p>
            </div>
            <p onClick={() => setModalShow(true)} className="DownloadBrochureButton m-auto p-lg-1">
              Talk to the Expert
            </p>
          </Card>
        </Col>
      </Row>
      <Enquries show={modalShow} onHide={() => setModalShow(false)} />
    </Container>
  );
}
