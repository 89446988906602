import React from "react";
import { Row, Col, Container} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

export default function Footer() {

  const FootYear = new Date().getFullYear()
  return (
    <div>
      
      <div className="Footer mt-5 mt-md-5 mx-auto mx-md-auto">
        <Container>
          <Row className="mx-md-auto mx-auto p-4">
            <Col lg={3} md={3}>
              <ul className="Footerlists list-unstyled  text-start">
                <h4>Programmes</h4>
                <a href="/programme">
                  <li>
                    <FontAwesomeIcon icon={faAngleRight} className="mx-2" />
                    Programmes
                  </li>
                </a>
                <a href="/faculty">
                <li>
                  <FontAwesomeIcon icon={faAngleRight} className="mx-2" />
                  Faculty
                </li>
                </a>
                <a href="/JobOpportunities">
                  <li>
                    <FontAwesomeIcon icon={faAngleRight} className="mx-2" />
                    Job Opportunities
                  </li>
                </a>
              </ul>
            </Col>
            <Col lg={3} md={3}>
              <ul className="Footerlists list-unstyled  text-start">
                <h4>About Us</h4>
                <a href="/about">
                  <li>
                    <FontAwesomeIcon icon={faAngleRight} className="mx-2" />
                    The Management
                  </li>
                </a>
                <a href="/about/Infrastructure">
                  <li>
                    <FontAwesomeIcon icon={faAngleRight} className="mx-2" />
                    Infrastructure
                  </li>
                </a>
                <a href="/about/StudentsActivites">
                  <li>
                    <FontAwesomeIcon icon={faAngleRight} className="mx-2" />
                    Student Activities
                  </li>
                </a>
              </ul>
            </Col>
            <Col lg={3} md={3}>
              <ul className=" Footerlists list-unstyled  text-start">
                <h4>Contact Us</h4>
                <li>242-B, Trichy Road, Sulur,</li>
                <li>Coimbatore, TamilNadu, India</li>
                <li>Office: 0422 2687421 / 603</li>
                <li>Extn: 225</li>
              </ul>
            </Col>
            <Col lg={3} md={3}>
              <Row className="FooterMedia text-start">
                <h4>Keep connected</h4>
                <ul className="list-unstyled my-2 mx-md-auto mx-auto">
                 <a href="https://www.facebook.com/rvssulur.pharmacy/" target="_blank" rel="noopener noreferrer">
                 <li>
                    <FontAwesomeIcon icon={faFacebookF} />
                  </li>
                 </a>
                 {/* <a href="">
                 <li>
                    <FontAwesomeIcon icon={faTwitter} />
                  </li>
                 </a> */}
                  <a href="https://www.youtube.com/@rvssulur-pharmacy" target="_blank" rel="noopener noreferrer">
                  <li>
                    <FontAwesomeIcon icon={faYoutube} />
                  </li>
                  </a>
                  <a href="https://www.instagram.com/rvssulur.pharmacy/" target="_blank" rel="noopener noreferrer">
                  <li>
                    <FontAwesomeIcon icon={faInstagram} />
                  </li>
                  </a>
                 <a href="https://www.linkedin.com/company/rvssulur-pharmacy/" target="_blank" rel="noopener noreferrer">
                    <li>
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </li>
                 </a>
                </ul>
              </Row>
            </Col>
          </Row>
      </Container>
      </div>
      <div className="FooterCopyRight p-3">
        <p className="text-center">
          © {FootYear} RVS COLLEGE OF PHARMACEUTICAL SCIENCE.All rights
          reserved{" "}
        </p>
      </div>
    </div>
  );
}
